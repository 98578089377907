import * as React from 'react';
import {Navigate} from 'react-router-dom';

/** Helpers */
import {validateToken/*, hasUserAccounts, hasUserActiveAccount*/} from '../Utils/helpers';

/** Constants */
import {AUTH_USER_TOKEN_KEY/*, AUTH_USER_ACTIVE_ACCOUNT*/} from '../Utils/constants';

const PublicRoutes = ({children, onlyLogout}) => {

    const checkUserAuth = validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));

    if (onlyLogout && checkUserAuth) {
        return (<Navigate
            to={{
                pathname: '/uebersicht'
            }}
        />);
    }

    return (children);
};

export default PublicRoutes;
