import React, {useState, useEffect} from 'react';
import {Button, Card, Col, PageHeader, Row, Statistic, Modal, Form, Select, notification} from 'antd';
import moment from 'moment';

import ApiWrapper from '../Utils/ApiWrapper';

import SiteLayout from '../Components/Styled/SiteLayout';
import {Link, useNavigate, useParams} from "react-router-dom";
import {getActiveAccountId, translatePruefer} from "../Utils/helpers";
import ChangeBuildingpart from "../Components/ChangeBuildingAndPart";
import CollapsibleCard from "../Components/Styled/CollapsibleCard";
import {useSelector} from "react-redux";
import {fetchDataAsync, selectBuildingpartForObservable, selectObject, selectObservable} from "../app/dataSlice";
import {selectObservableType} from "../app/observabletypesSlice";
import {useAppDispatch} from "../app/hooks";

const ElementDetailsContainer = (props) => {

    const params = useParams();

    const [form] = Form.useForm();

    const [visible, setVisible] = useState(false);
    const [modalChangeBuildingpartVisible, setModalChangeBuildingpartVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [elementToDelete, setElementToDelete] = useState({});
    const [kategorie, setKategorie] = useState({});

    const objektId = params.objektId;
    const elementId = params.elementId;
    const buildingId = params.buildingId;
    const buildingpartId = params.buildingpartId;

    const elementDetails = useSelector(selectObservable(elementId));
    const buildingpartDetails = useSelector(selectBuildingpartForObservable(elementId));
    const observableType = useSelector(selectObservableType(elementDetails.categoryId));

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const deleteElementFromDb = () => {

        ApiWrapper.del('objekt-verwaltung-api', `/${props.activeAccountId}/object/${objektId}/building/${buildingId}/part/${buildingpartId}/observable/${elementId}`, {}).then((result) => {
            setVisible(false);
            setConfirmLoading(false);
            console.log("kein error")
            dispatch(fetchDataAsync({activeAccountId: getActiveAccountId()}));
            navigate(`/objekt/${objektId}`, {replace: true});
        }).catch(err => {
            console.log(err);
            setVisible(true);
            setConfirmLoading(false);
        });
    };

    const showModal = () => {
        setElementToDelete(elementId);
        setVisible(true);
    };

    const showModalEditBuildingPart = () => {
        setElementToDelete(elementId);
        setModalChangeBuildingpartVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        deleteElementFromDb(elementToDelete.id);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleOkModalBuildingpart = async () => {

        try {
            const values = await form.validateFields();
            console.log(values);

            ApiWrapper.put('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building/${buildingId}/part/${buildingpartId}/observable/${elementId}/move`, {
                buildingpartId: values.part
            }).then(async (result) => {
                notification.success({
                    message: 'Änderung gespeichert!',
                    description: 'Die Änderung wurde gespeichert!',
                    placement: 'topRight',
                    duration: 5
                });
                setModalChangeBuildingpartVisible(false);
                dispatch(fetchDataAsync({activeAccountId: getActiveAccountId()}));
            }).catch(err => {
                console.log(err);
                notification.error({
                    message: 'Fehler bei der Verschiebung!',
                    description: 'Das Element konnte auf Grund eines Fehlers nicht verschoben werden!',
                    placement: 'topRight',
                    duration: 5
                });
            });
        } catch (err) {
            if (err.errorFields) {
                for (const errorField of err.errorFields) {
                    notification.error({
                        message: `Fehler in Feld ${errorField.name.join('')}`,
                        description: errorField.errors.join(', '),
                        placement: 'topRight',
                        duration: 5
                    });
                }
            } else {
                notification.error({
                    message: 'Fehler bei der Eingabe!',
                    description: 'Bei der Validierung der Eingabe kam es zu einem Fehler!',
                    placement: 'topRight',
                    duration: 5
                });
            }
            console.log(err);
        }


    };

    const handleCancelModalBuildingpart = () => {
        setModalChangeBuildingpartVisible(false);
    };

    const rendernaechstePruefungen = () => {
        const items = [];

        if (elementDetails.naechstePruefungen) {
            for (const [i, naechstePruefungen] of elementDetails.naechstePruefungen.entries()) {
                if (!naechstePruefungen.preview) {
                    items.push(
                        <Card key={`${i + 1}# ${naechstePruefungen.titel}`} type="inner"
                              title={`${i + 1}# ${naechstePruefungen.titel}`}>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Statistic title="Titel" value={naechstePruefungen.titel}/>
                                    <Statistic title="Beschreibung" value={naechstePruefungen.beschreibung}/>
                                </Col>
                                <Col span={6}>
                                    <Statistic title="Letzte Prüfung"
                                               value={moment(naechstePruefungen.letztePruefung).format('DD.MM.YYYY')}/>
                                    <Statistic title="Nächste Prüfung"
                                               value={moment(naechstePruefungen.naechstePruefung).format('DD.MM.YYYY')}/>
                                </Col>
                                <Col span={6}>
                                    <Statistic title="Prüfung von" value={translatePruefer(naechstePruefungen.pruefungVon)}/>
                                </Col>
                            </Row>
                        </Card>
                    );
                }
            }
        }
        return items;
    };

    const downloadFile = async (attachmentId) => {
        await ApiWrapper.download('objekt-verwaltung-api', `/${getActiveAccountId()}/objekte/${objektId}/download/${attachmentId}`)
    }

    const renderAttachments = (vergangenePruefungen) => {

        if (!vergangenePruefungen.attachments || !Array.isArray(vergangenePruefungen.attachments)) {
            return(<p>Keine Anhänge vorhanden</p>)
        }

        const attachments = [];

        for (const att of vergangenePruefungen.attachments) {
            attachments.push(<Link to={""} onClick={() => downloadFile(att.id)}>-> {att.name}</Link>);
        }

        return attachments;

    }

    const rendervergangenePruefungen = () => {
        const items = [];

        if (elementDetails.vergangenePruefungen) {
            for (const [i, vergangenePruefungen] of elementDetails.vergangenePruefungen.entries()) {

                items.push(
                    <Card key={`${i + 1}# ${vergangenePruefungen.titel}`} type="inner"
                          title={`${i + 1}# ${vergangenePruefungen.titel}`} extra={<Link to={`/objekt/${vergangenePruefungen.objektId}/building/${buildingId}/part/${buildingpartId}/element/${vergangenePruefungen.elementId}/pruefung/${vergangenePruefungen.id}/details`}>Details</Link>}>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Statistic title="Titel" value={vergangenePruefungen.titel}/>
                                <Statistic title="Beschreibung" value={vergangenePruefungen.beschreibung}/>
                            </Col>
                            <Col span={12}>
                                <Statistic title="Prüfung durchgeführt"
                                           value={moment(vergangenePruefungen.letztePruefung).format('DD.MM.YYYY')}/>
                                <Statistic title="Prüfungsfrist"
                                           value={moment(vergangenePruefungen.naechstePruefung).format('DD.MM.YYYY')}/>
                                <Statistic title="Prüfung von" value={translatePruefer(vergangenePruefungen.pruefungVon)}/>
                            </Col>
                        </Row>

                        <h2>Anhänge</h2>
                        {renderAttachments(vergangenePruefungen)}

                    </Card>
                );

            }
        }
        return items;
    };

    return (
        <SiteLayout pageTitle="Detailansicht Element" breadcumItems={[
            <Link to={`/objekte`}>Account</Link>,
            <Link to={`/objekt/${objektId}`}>Objekt</Link>,
            'Element'
        ]}>
            <PageHeader
                ghost={false}
                title={elementDetails.titel}
                extra={[
                    <Button key="1"><Link to={`/objekt/${objektId}/building/${buildingId}/part/${buildingpartId}/element/${elementId}/edit`}>Bearbeiten</Link></Button>,
                    <Button key="2" onClick={() => showModalEditBuildingPart()}>Raum ändern</Button>,
                    <Button key="3" onClick={() => showModal()}>Löschen</Button>,
                    <Button key="4"><Link to={`/objekt/${objektId}/pruefungen/dokumentieren`}>Prüfung
                        dokumentieren</Link></Button>,
                ]}
            >
            </PageHeader>
            <Card title="Details" bordered={true} style={{'text-align': 'left', 'margin': '10px 0'}}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Statistic title="Name" value={elementDetails.titel}/>
                        <Statistic title="Beschreibung" value={elementDetails.beschreibung}/>
                        <Statistic title="Etage / Raum " value={`${buildingpartDetails.floor ?? ''} - ${buildingpartDetails.name ?? ''}`}/>
                        <Statistic title="Kategorie" value={observableType.titel}/>
                    </Col>
                    <Col span={12}>
                        <Statistic title="Angelegt am" value={moment(elementDetails.createdAt).format('DD.MM.YYYY')}/>
                        <Statistic title="Eingebaut am"
                                   value={elementDetails.einbauDatum ? moment(elementDetails.einbauDatum).format('DD.MM.YYYY') : 'Unbekannt'}/>
                        <Statistic title="Geräte ID" value={elementDetails.geraeteId}/>
                        <Statistic title="Zuletzt bearbeitet"
                                   value={moment(elementDetails.updatedAt).format('DD.MM.YYYY')}/>
                    </Col>
                </Row>
            </Card>
            <CollapsibleCard title="Vorgesehene Prüfungen" bordered={true} style={{'text-align': 'left', 'margin': '10px 0'}}>
                {rendernaechstePruefungen()}
            </CollapsibleCard>
            <CollapsibleCard title="Vergangene Prüfungen" bordered={true} style={{'text-align': 'left', 'margin': '10px 0'}}>
                {rendervergangenePruefungen()}
            </CollapsibleCard>
            <React.Fragment>
                <Modal
                    title="Soll das Element wirklich gelöscht werden?"
                    visible={visible}
                    okText={"OK"}
                    onOk={handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}
                    destroyOnClose={true}
                >

                    <div style={{alignItems:'center', textAlign: 'center'}}>Dadurch wird das Element <b> &nbsp;{elementDetails.titel} &nbsp;</b> unwiederruflich gelöscht!</div>

                </Modal>
                <Modal
                    title="Gebäudeteil für das Element ändern"
                    visible={modalChangeBuildingpartVisible}
                    okText={"OK"}
                    onOk={handleOkModalBuildingpart}
                    onCancel={handleCancelModalBuildingpart}
                    cancelText={"Abbrechen"}
                    destroyOnClose={true} // bringt nichts, da die Daten in ChangeBuildingpart gespeichert werden.
                >
                    <ChangeBuildingpart form={form} element={elementDetails}/>
                </Modal>
            </React.Fragment>
        </SiteLayout>
    );
};
export default ElementDetailsContainer;
