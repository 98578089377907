import React, {useState, useEffect} from 'react';
import SiteLayout from '../Components/Styled/SiteLayout';

import {Link, useSearchParams} from 'react-router-dom';
import {
    Badge, Button, Calendar, PageHeader, Space, Radio, Collapse, Select, ConfigProvider, Modal, Tooltip, DatePicker
} from 'antd';
import {InfoCircleOutlined, FilterOutlined, ExportOutlined} from "@ant-design/icons";

import TablePruefungen from "../Components/Styled/TablePruefungen";

import de_DE from 'antd/lib/locale/de_DE';
import moment from 'moment';
import 'moment/locale/de';

moment.locale('de');

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {useSelector} from "react-redux";
import {selectData} from "../app/dataSlice";

const {Panel} = Collapse;
const {Option} = Select;
const {RangePicker} = DatePicker;

const ViewPruefungenContainer = (props) => {

    const [searchParams] = useSearchParams();

    const [accountAnstehendePruefungen, setAccountAnstehendePruefungen] = useState([]);
    const [accountVergangenePruefungen, setAccountVergangenePruefungen] = useState([]);
    const [accountPruefungen, setAccountPruefungen] = useState([]);
    const [accountPruefungenToShow, setAccountPruefungenToShow] = useState([]);
    const [loading, setLoading] = useState(true);
    const [objektFilter, setObjektFilter] = useState([]);
    const [elementFilter, setElementFilter] = useState([]);
    const [anzeigeArt, setAnzeigeArt] = useState('Kalender');
    const [pruefungsArt, setPruefungsArt] = useState('Anstehende');
    const [pruefungsArtActiv, setPruefungsArtActiv] = useState(true);
    const [filterObjekt, setFilterObjekt] = useState('');
    const [filterElemente, setFilterElemente] = useState('');
    const [filterDateFrom, setFilterDateFrom] = useState(undefined);
    const [filterDateTo, setFilterDateTo] = useState(undefined);
    const [isFilterModalVisible, setFilterModalVisible] = useState(false);

    const data = useSelector(selectData);

    const loadAnstehendePruefungen = async () => {
        setLoading(true);
        setElementFilter([]);
        setObjektFilter([]);
        const elementListe = []
        const vergangenePruefungenListe = [];
        const anstehendePruefungenListe = [];
        try {

            const accountData = JSON.parse(JSON.stringify(data));
            if (accountData && accountData.objekte) {
                for (let objekt of accountData.objekte) {

                    const objektFilterEintrag = {
                        text: objekt.titel, value: objekt.id, elemente: []
                    }

                    for (const building of objekt.buildings) {
                        for (const part of building.parts) {
                            for (let element of part.elemente) {

                                const elementFilterEintrag = {
                                    text: element.titel, value: element.id
                                };
                                objektFilterEintrag.elemente.push(elementFilterEintrag);
                                //elementFilter.push(elementFilterEintrag);
                                //setElementFilter(elementFilter);

                                if (Array.isArray(element.naechstePruefungen)) {
                                    elementListe.push(element);

                                    for (let pruefung of element.naechstePruefungen) {

                                        pruefung.objektTitel = objekt.titel;
                                        pruefung.objektId = objekt.id;

                                        pruefung.element = {
                                            titel: element.titel, buildingpart: part
                                        };
                                        anstehendePruefungenListe.push(pruefung);
                                    }
                                }

                                if (Array.isArray(element.vergangenePruefungen)) {
                                    elementListe.push(element);

                                    for (let pruefung of element.vergangenePruefungen) {

                                        pruefung.objektTitel = objekt.titel;
                                        pruefung.objektId = objekt.id;

                                        pruefung.element = {
                                            titel: element.titel, etage: element.etage, raum: element.raum
                                        };
                                        pruefung.details = <Link
                                            to={`/objekt/${pruefung.objektId}/building/${part.buildingId}/part/${element.buildingpartId}/element/${pruefung.elementId}/pruefung/${pruefung.id}/details`}>
                                            <InfoCircleOutlined/> </Link>
                                        vergangenePruefungenListe.push(pruefung);
                                    }
                                }
                            }
                        }
                    }
                    objektFilter.push(objektFilterEintrag);
                    setObjektFilter(objektFilter);

                }
                setAccountAnstehendePruefungen(anstehendePruefungenListe);
                setAccountVergangenePruefungen(vergangenePruefungenListe);

            }

            if (searchParams.objektId) {
                onFilterObjektChanged(searchParams.objektId)
            }

            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadAnstehendePruefungen();
    }, [props.accountData]);

    const IconText = ({icon, text}) => (<Space>
        {React.createElement(icon)}
        {text}
    </Space>);

    function dateCellRender(value) {
        let badges = [];

        if (value.isSame(moment(), 'day')) {
            const prTodayOrBefore = accountPruefungenToShow.filter(pruefung => value.isSameOrAfter(moment(pruefung.naechstePruefung), 'day'));
            badges.push(<Badge status="error" text={prTodayOrBefore.length}/>)
        }
        const prToday = accountPruefungenToShow.filter(pruefung => value.isSame(moment(pruefung.naechstePruefung), 'day'));
        if (prToday.length !== 0) {
            badges.push(<Badge status="warning" text={prToday.length}/>)
        }

        return badges;
    }

    const anzeigeOptionen = [{label: 'Kalender', value: 'Kalender'}, {label: 'Tabelle', value: 'Tabelle'},];

    const onChangeAnzeige = (e) => {
        setAnzeigeArt(e.target.value);
    }

    const pruefungsOptionen = [{label: 'Erledigte', value: 'Vergangene'}, {label: 'Anstehende', value: 'Anstehende'},];

    const onChangePruefungen = (e) => {
        setPruefungsArt(e.target.value);

        if (e.target.value === 'Vergangene') {
            setAccountPruefungen(accountVergangenePruefungen);
            setAccountPruefungenToShow(accountVergangenePruefungen);
            setPruefungsArtActiv(false);
        } else {
            setAccountPruefungen(accountAnstehendePruefungen);
            setAccountPruefungenToShow(accountAnstehendePruefungen);
            setPruefungsArtActiv(true);
        }
        setFilterObjekt('');
    }

    const onFilterObjektChanged = value => {

        if (value && value !== '') {
            const elementeToFilterFromObject = objektFilter.find(objekt => objekt.value === value);
            setElementFilter(elementeToFilterFromObject.elemente)
            setFilterObjekt(value);
        } else {
            setFilterObjekt('');
            setElementFilter([]);
        }
    }

    const onFilterObjektCleared = () => {
        setFilterObjekt('');
        setElementFilter([]);
        setAccountPruefungenToShow(accountPruefungen);
    }

    const onFilterElementeChanged = value => {
        setFilterElemente(value);
    }

    const onFilterElementeCleared = () => {
        setFilterElemente('');
    }

    const onChangeRangePicker = (dates, dateStrings) => {
        if (dates) {
            setFilterDateFrom(dates[0]);
            setFilterDateTo(dates[1]);
        } else {
            setFilterDateFrom(undefined);
            setFilterDateTo(undefined);
        }
    }

    const selectDayInCalendar = (day) => {
        console.log(day)
        setFilterDateFrom(day);
        setFilterDateTo(day);
        setAnzeigeArt('Tabelle');
    }

    useEffect(() => {
        //console.log(searchParams.get('objektId'));
        onFilterObjektChanged(searchParams.get('objektId'))
    }, [searchParams]);

    useEffect(() => {
        let filteredPruefungen = [];

        if (pruefungsArtActiv) {
            filteredPruefungen = accountAnstehendePruefungen;
        } else {
            filteredPruefungen = accountVergangenePruefungen;
        }
        if (filteredPruefungen) {
            if (filterObjekt && filterObjekt !== '') {
                filteredPruefungen = filteredPruefungen.filter(pruefung => pruefung.objektId === filterObjekt);
            }

            if (filterElemente && filterElemente !== '') {
                filteredPruefungen = filteredPruefungen.filter(pruefung => pruefung.objektId === filterElemente);
            }

            if (filterDateFrom && filterDateFrom instanceof moment && filterDateTo && filterDateTo instanceof moment) {
                filteredPruefungen = filteredPruefungen.filter(pruefung => {
                    if (
                        moment().isSameOrAfter(moment(pruefung.naechstePruefung), 'day') && // Nur bei überfälligen
                        filterDateFrom.isSameOrBefore(moment(), 'day') && // nur wenn der Filter vor bzw. heute ist
                        pruefungsArtActiv && // nur bei anstehenden prüfungen
                        !pruefung.preview
                    ) {
                        return true;
                    }
                    return filterDateFrom.isSameOrBefore(moment(pruefung.naechstePruefung), 'day') &&
                        filterDateTo.isSameOrAfter(moment(pruefung.naechstePruefung), 'day');
                });
            }
        }


        setAccountPruefungenToShow(filteredPruefungen)
    }, [pruefungsArtActiv, accountAnstehendePruefungen, accountVergangenePruefungen, filterObjekt, filterElemente, filterDateFrom, filterDateTo]);

    const onClickFilterModal = () => {
        setFilterModalVisible(true);
    }

    const onClickOkFilterModal = () => {
        //console.log("Filter muss noch gesetzt werden. ")
        setFilterModalVisible(false);
    }

    const onClickQuitFilterModal = () => {
        setFilterModalVisible(false);
    }

    const clearAllFilters = () => {
      setFilterDateFrom(undefined);
      setFilterDateTo(undefined);
      onFilterElementeCleared();
      onFilterObjektCleared();
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {

        const pruefugenToExport = [];

        for (const pr of csvData) {
            if (pr.element !== undefined) {

                pruefugenToExport.push({
                    'Prüfung': pr.titel,
                    'Beschreibung': pr.beschreibung,
                    'Objekt': pr.objektTitel,
                    'Element': pr.element.titel,
                    'Etage': pr.element.etage,
                    'Raum': pr.element.raum,
                    'Letzte Prüfung': moment(pr.letztePruefung).format('DD.MM.YYYY'),
                    'Nächste Prüfung': moment(pr.naechstePruefung).format('DD.MM.YYYY'),
                    'Prüfung von': pr.pruefungVon,
                    'Art': pr.art,
                });
            } else {
                //console.log(csvData)
            }
        }
        //console.log(pruefugenToExport)

        const ws = XLSX.utils.json_to_sheet(pruefugenToExport);
        const wb = { Sheets: { 'Pruefungen': ws }, SheetNames: ['Pruefungen'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    const renderData = () => {
        if (anzeigeArt === 'Kalender') {
            return (<ConfigProvider locale={de_DE}>
                <Calendar
                    dateCellRender={dateCellRender}
                    onSelect={selectDayInCalendar}
                />
            </ConfigProvider>)
        } else {
            return (<ConfigProvider locale={de_DE}>
                <TablePruefungen accountPruefungen={accountPruefungenToShow}
                                 loading={loading}
                                 objektFilter={[]}
                                 elementFilter={[]}
                                 activ={pruefungsArtActiv}
                />
            </ConfigProvider>)
        }
    }

    return (<SiteLayout pageTitle="Übersicht der Prüfungen">

        <PageHeader
            title="Übersicht aller Prüfungen"
            ghost={false}
            extra={[
              <Radio.Group optionType="button" options={anzeigeOptionen} onChange={onChangeAnzeige}
                           value={anzeigeArt}/>,

              <Button onClick={() => onClickFilterModal()}>
                <IconText icon={FilterOutlined} text="Filter" key="Filter"/>
              </Button>,

              <Button type="primary" onClick={() => exportToCSV(accountPruefungenToShow, 'export')}>
                <IconText icon={ExportOutlined} text="Exportieren" key="Export"/>
              </Button>,

              <Tooltip title="Bitte wählen Sie erst ein Objekt in den Anzeigeeinstellungen aus." style={{margin: 0}}>
                  <Button type="primary"  disabled={filterObjekt === ''}>
                      <Link to={`/objekt/${filterObjekt}/pruefungen/dokumentieren`}>Dokumentieren</Link>
                  </Button>
              </Tooltip>,

            ]}>
        </PageHeader>
        <br/>

        <Modal
            title="Filter"
            visible={isFilterModalVisible}
            onOk={onClickOkFilterModal}
            onCancel={onClickQuitFilterModal}
        >
            <div style={{width: '80%', textAlign: 'center', marginLeft:'10%'}}>
                <Radio.Group optionType="button" options={pruefungsOptionen} onChange={onChangePruefungen}
                             value={pruefungsArt} style={{width: '90%'}}/>
                <br/> <br/>

                <Select
                    placeholder="Objekt filtern"
                    style={{width: '80%'}}
                    onChange={onFilterObjektChanged}
                    onClear={onFilterObjektCleared}
                    value={filterObjekt || undefined} // undefined, sonst wird der Placeholder nicht angezeigt.
                >
                    {objektFilter.map(filter => (<Option key={filter.value}>{filter.text}</Option>))}
                </Select>

                <br/> <br/>

                <Select
                    placeholder="Element filtern"
                    style={{width: '80%'}}
                    onChange={onFilterElementeChanged}
                    onClear={onFilterElementeCleared}
                    value={filterElemente || undefined} // undefined, sonst wird der Placeholder nicht angezeigt.
                >
                    {elementFilter.map(filter => (<Option key={filter.value}>{filter.text}</Option>))}
                </Select>

                <br/><br/>
                <ConfigProvider locale={de_DE}>
                    <RangePicker
                        style={{width: '80%'}}
                        value={[filterDateFrom, filterDateTo]}
                        onChange={onChangeRangePicker}
                    />
                </ConfigProvider>

                <br/><br/>
                <Button key="clearFilterButton" onClick={clearAllFilters}>
                    Filter zurücksetzen
                </Button>
            </div>
        </Modal>

        {renderData()}
    </SiteLayout>);
};

export default ViewPruefungenContainer;
