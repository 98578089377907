import * as React from 'react';
import { Result, Button } from 'antd';

const NotFoundContainer = (props) => {

  return (
    <Result
    status="404"
    title="404"
    subTitle="Sorry, diese Seite gibt es nicht"
    extra={<Button type="primary" href='/'>Zurück zur Startseite</Button>}
  />
  );
};

export default NotFoundContainer;
