import React, {useEffect, useState} from 'react';
import SiteLayout from '../Components/Styled/SiteLayout';
import {Link, useNavigate, useParams} from 'react-router-dom';

import ApiWrapper from '../Utils/ApiWrapper';

import {Button, Form, Input, notification, PageHeader, Select} from 'antd';
import {MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {getActiveAccountId} from "../Utils/helpers";
import {fetchDataAsync} from "../app/dataSlice";
import {useAppDispatch} from "../app/hooks";

const {TextArea} = Input;
const {Option} = Select;

const NotificationUserEditContainer = (props) => {
    const [form] = Form.useForm();

    const navigate = useNavigate();
    const params = useParams();

    const [objektDetails, setObjektDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const objektId = params.objektId;
    const dispatch = useAppDispatch();


    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16,}
    };

    const tailLayout = {
        wrapperCol: {
            offset: 8,
            span: 16,
        },
    };

    const loadObjekt = async () => {

        const accountData = props.accountData;
        if (accountData && accountData.objekte) {
            for (let objekt of accountData.objekte) {
                if (objekt.id == objektId) {
                    setObjektDetails(objekt);
                    setLoading(false);
                }
            }
        }

    };

    const onFinish = (values) => {
        values.beschreibung = objektDetails.beschreibung;
        values.city = objektDetails.city;
        values.plz = objektDetails.plz;
        values.street = objektDetails.street;
        values.titel = objektDetails.titel;

        // ****************************
        // Eigentlich die falsche Stelle für eine Validierung?
        //******************************

        // Validierung Für SMS und MAIL - mit anschließender Fehlermeldung und Abbruch
        for (let benachrichtigung of values.benachrichtigungen) {
            if (benachrichtigung.versandart == 'sms') {
                if (!/^([+]{0,1}[0-9\s]{7,18})$/.test(benachrichtigung.empfaengerListe)) {
                    notification.error({
                        message: 'Fehler!',
                        description: 'Die Nummer darf nur aus Zahlen bestehen. Die Ländervorwahl optional mit einem führenden "+" angeben.',
                        placement: 'topRight',
                        duration: 10
                    });
                    return;
                }
            }
            if (benachrichtigung.versandart == 'email') {
                if (!/^([A-Za-z0-9-._+]{3,30}[@]{1,1}[A-Za-z0-9-_+]{3,30}[.]{1,1}[A-Za-z0-9]{2,3})$/.test(benachrichtigung.empfaengerListe)) {
                    notification.error({
                        message: 'Fehler!',
                        description: 'Die angegebene E-Mail Adresse ist nicht gültig',
                        placement: 'topRight',
                        duration: 10
                    });
                    return;
                }
            }
        }

        ApiWrapper.put('objekt-verwaltung-api', `/${props.activeAccountId}/objekt/${objektId}`, values).then(async (result) => {
            console.log(result);
            notification.success({
                message: 'Benachrichtung bearbeitet!',
                description: 'Die Benachrichtung wurde erfolgreich bearbeitet!',
                placement: 'topRight',
                duration: 10
            });
            dispatch(fetchDataAsync({activeAccountId: getActiveAccountId()}));
            navigate(`/objekt/${objektId}`);
        }).catch(err => {
            console.log(err);
        });
        console.log('Success:', 'ok');
    };

    const onFinishFailed = (errorInfo) => {
        notification.error({
            message: 'Fehler!',
            description: 'Sie haben nicht alle Pflichtfelder ausgefüllt',
            placement: 'topRight',
            duration: 10
        });
        console.log('Failed:', errorInfo);
    };

    useEffect(async () => {
        setLoading(true);
        await loadObjekt();
    }, []);

    if (loading) {
        return null;
    }

    return (
        <SiteLayout pageTitle="Detailansicht Element" breadcumItems={[
            <Link to={`/uebersicht`}>Startseite</Link>,
            <Link to={`/objekte`}>Objektübersicht</Link>,
            <Link to={`/objekt/${objektId}`}>{objektDetails.titel}</Link>,
            'Benachrichtung bearbeiten'
        ]}>
            <PageHeader
                title={`Benachrichtung bearbeiten: ${objektDetails.titel}`}
                ghost={false}
            >
            </PageHeader>
            <br/>
            <div style={{width: '80%', textAlign: 'left', marginLeft:'10%'}}>

                <Form form={form}
                      {...layout}
                      name="basic"
                      initialValues={{remember: false, benachrichtigungen: objektDetails.benachrichtigungen}}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      labelAlign="left"
                >

                    <Form.List name="benachrichtigungen">

                        {(fields, {add, remove}) => (
                            <React.Fragment>
                                {fields.map(field => (
                                    <React.Fragment key={field.key}>
                                        <hr/>
                                        <h2>Benachrichtung bearbeiten </h2>
                                        <Form.Item
                                            {...field}
                                            label="Tage bevor Fälligkeit"
                                            name={[field.name, 'numberOfDaysBefore']}
                                            fieldKey={[field.fieldKey, 'numberOfDaysBefore']}
                                            rules={[{required: true, message: 'Anzahl der Tage fehlt'}]}
                                        >
                                            <Input placeholder="XX Tage"/>
                                        </Form.Item>
                                        <Form.Item label="Zustellungen">
                                            <Input.Group compact>
                                                <Form.Item
                                                    name={[field.name, 'versandart']}
                                                    noStyle
                                                    rules={[{required: true, message: 'Versandart fehlt'}]}

                                                >
                                                    <Select placeholder="Bitte wählen" className="select-after" style={{ width: '30%' }}>
                                                        <Option value="email">Email</Option>
                                                        <Option value="sms">SMS</Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    name={[field.name, 'empfaengerListe']}
                                                    noStyle
                                                    rules={[{required: true, message: 'Empfänger fehlt'}]}
                                                    style={{ width: '70%' }}
                                                >
                                                    <Input
                                                        placeholder="E-Mail Adresse eintragen" style={{ width: '70%' }}/>
                                                </Form.Item>
                                            </Input.Group>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => remove(field.name)} block
                                                    icon={<MinusCircleOutlined/>} >
                                                Benachrichtung löschen
                                            </Button>
                                        </Form.Item>
                                    </React.Fragment>
                                ))}
                                <hr/>
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusCircleOutlined/>}
                                    >
                                        Benachrichtung hinzufügen
                                    </Button>
                                </Form.Item>
                            </React.Fragment>
                        )}
                    </Form.List>
                    <Button htmlType='button' style={{marginLeft: 8, marginRight: 8, marginBottom: 12}}>
                        <Link to={`/objekt/${objektId}`}>Abbrechen</Link>
                    </Button>
                    <Button type="primary" htmlType="submit" style={{marginLeft: 8, marginRight: 8}}>
                        Speichern
                    </Button>
                </Form>
            </div>

        </SiteLayout>
    );
};


export default NotificationUserEditContainer;
