import React, { useState ,useEffect } from 'react';

import { Auth } from 'aws-amplify';
import {Form, Input, Button, notification, Spin, Row, Col, Modal} from 'antd';

import SiteLayout from '../Components/Styled/SiteLayout';

import {QRCodeSVG} from 'qrcode.react';
import {BarcodeOutlined, LoadingOutlined, LockOutlined, MessageOutlined} from "@ant-design/icons";


const MfaSettingContainer = (props) => {

    const [user, setUser] = useState(undefined);
    const [preferredMFA, setPreferredMFA] = useState(undefined);
    const [isTotpModalVisible, setIsTotpModalVisible] = useState(false);
    const [isTotpModalLoading, setIsTotpModalLoading] = useState(false);
    const [qrcode_data, setQrcode_data] = useState("");

    const issuer = "Pruver";

    useEffect(async () => {
        const tmpUser = await Auth.currentAuthenticatedUser()
        setUser(tmpUser);
        console.log(tmpUser)
    }, []);

    const loadPreferredMFA = async () => {
        setPreferredMFA(await Auth.getPreferredMFA(user, {
            bypassCache: false
        }));
    }

    useEffect(async () => {
        await loadPreferredMFA();
    }, [user]);

    const setup_totp = async () => {
        setIsTotpModalLoading(true);
        const code = await Auth.setupTOTP(user);
        console.log(code)
        setQrcode_data("otpauth://totp/AWSCognito:"+ user.username + "?secret=" + code + "&issuer=" + issuer);
        setIsTotpModalVisible(true);
        setIsTotpModalLoading(false);
    }

    const handleOk = () => {
        setIsTotpModalVisible(false);
    };

    const handleCancel = () => {
        setIsTotpModalVisible(false);
    };

    const onFinishFirstTotp = async (values) => {
        const result = await Auth.verifyTotpToken(user, values.totp);
        console.log(result)
        const preMFA = await Auth.setPreferredMFA(user, 'TOTP');
        console.log(preMFA)
        setIsTotpModalVisible(false);
        await loadPreferredMFA();
    };

    const renderModalContent = () => {
        if (isTotpModalLoading) {
            return null;
        }
        return (
            <React.Fragment>
                <QRCodeSVG value={qrcode_data}/>
                <Form onFinish={onFinishFirstTotp} className="login-form">
                    <Form.Item name="totp" rules={[
                        {
                            required: true,
                            message: 'Bitte gib dein Passwort ein'
                        }
                    ]}>
                        <Input
                            prefix={<LockOutlined/>}
                            type="text"
                            placeholder="Code"
                        />
                        <Button
                            style={{width: '100%'}}
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                        >
                            Einrichten
                        </Button>
                    </Form.Item>
                </Form>
            </React.Fragment>
        )
    }

    const disableMfa = async () => {
        await Auth.setPreferredMFA(user, 'NOMFA');
        await loadPreferredMFA();
    }

    return (
        <SiteLayout pageTitle="Element Bearbeiten">

            Aktuelle Einstellung: {preferredMFA}
            <br/>
            Einrichten:
            <Button onClick={setup_totp} icon={<BarcodeOutlined />}>App</Button>
            <Button icon={<MessageOutlined />} disabled>SMS </Button>
            <Button onClick={disableMfa} icon={<MessageOutlined />} danger>Deaktivieren </Button>

            <Modal title="MFA per App einrichten" visible={isTotpModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                {renderModalContent()}
            </Modal>

        </SiteLayout>
    );
};
export default MfaSettingContainer;
