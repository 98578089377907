import React, {useState} from 'react';
import {Modal, Space, Table, Tag} from 'antd';
import ApiWrapper from '../../Utils/ApiWrapper';
import {Link, useParams} from 'react-router-dom';
import {useAppDispatch} from "../../app/hooks";
import {fetchDataAsync} from "../../app/dataSlice";
import {getActiveAccountId} from "../../Utils/helpers";
import {useSelector} from "react-redux";
import {selectObservableTypes} from "../../app/observabletypesSlice";

const ElementList = (props) => {
    const params = useParams();

    const objektId = params.objektId;

    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [elementToDelete, setElementToDelete] = useState({});

    const dispatch = useAppDispatch();

    const observableTypes = useSelector(selectObservableTypes);

    const deleteElementFromDb = (element) => {
        console.log(element);
        ApiWrapper.del('objekt-verwaltung-api', `/${props.activeAccountId}/object/${element.objektId}/building/${props.buildingId}/part/${element.buildingpartId}/observable/${element.id}`, {}).then((result) => {
            console.log(result);
            setVisible(false);
            setConfirmLoading(false);
            dispatch(fetchDataAsync({activeAccountId: getActiveAccountId()}));
        }).catch(err => {
            console.log(err);
            setVisible(true);
            setConfirmLoading(false);
        });
    };

    const showModal = (record) => {
        setElementToDelete(record);
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        deleteElementFromDb(elementToDelete);
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setVisible(false);
    };


    const columns = [{
        title: 'Elementname',
        dataIndex: 'titel',
        key: 'titel',
    },
        {
            title: 'Kategorie',
            key: 'categoryId',
            dataIndex: 'categoryId',
            filters: observableTypes.map(type => {
                return {
                    text: type.titel,
                    value: type.id
                }
            }),
            onFilter: (value, record) => {
                return record && record.categoryId === value;
            },
            render: categoryId => {
                const category = observableTypes.find(observableType => observableType.id === categoryId);
                if (!category) return null;
                return (
                    <Tag color={'green'}>
                        {category.titel.toUpperCase()}
                    </Tag>
                );
            },
        },
        {
            title: 'Aktionen',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Link
                        to={`/objekt/${record.objektId}/building/${props.buildingId}/part/${record.buildingpartId}/element/${record.id}/details`}>Details</Link>
                    <Link
                        to={`/objekt/${record.objektId}/building/${props.buildingId}/part/${record.buildingpartId}/element/${record.id}/edit`}>Bearbeiten</Link>
                    <Link to={`#`} onClick={() => showModal(record)}>Löschen</Link>

                </Space>
            ),
        },
    ];

    return (
        <React.Fragment>
            <Table dataSource={props.elemente} columns={columns} loading={props.loading} rowKey={`ElementListe - ${() => Math.random().toString()}`}/>

            <Modal
                title="Soll das Element wirklich gelöscht werden?"
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <div style={{alignItems: 'center', textAlign: 'center'}}>Dadurch wird das
                    Element <b>&nbsp; {elementToDelete.titel} &nbsp;</b> unwiederruflich gelöscht!
                </div>
            </Modal>
        </React.Fragment>

    );
};

export default ElementList;
