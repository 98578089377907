import React, { lazy } from "react";

import IntroContent from "../content/IntroContent.json";
import MiddleBlockContent from "../content/MiddleBlockContent.json";
import AboutContent from "../content/AboutContent.json";
import MissionContent from "../content/MissionContent.json";
import ProductContent from "../content/ProductContent.json";
import ContactContent from "../content/ContactContent.json";

import Footer from "../Components/Footer";
import Header from "../Components/Header";

import GlobalStyles from "../globalStyles";

const ContactFrom = lazy(() =>
  import ("../Components/ContactForm"));
const ContentBlock = lazy(() =>
  import ("../Components/ContentBlock"));
const MiddleBlock = lazy(() =>
  import ("../Components/MiddleBlock"));
const Container = lazy(() =>
  import ("../common/Container"));

const PublicHomeContainer = () => {

  return (
    <React.Fragment>
    <GlobalStyles />
      <Header />
    <Container>
      <ContentBlock
        type="right"
        first="true"
        title={IntroContent.title}
        content={IntroContent.text}
        button={IntroContent.button}
        icon="house.jpg"
        id="intro"
      />
      <MiddleBlock
        title={MiddleBlockContent.title}
        content={MiddleBlockContent.text}
        button={MiddleBlockContent.button}
      />
      <ContentBlock
        type="left"
        title={AboutContent.title}
        content={AboutContent.text}
        section={AboutContent.section}
        icon="graphs.svg"
        id="about"
      />
      <ContentBlock
        type="right"
        title={MissionContent.title}
        content={MissionContent.text}
        icon="product-launch.svg"
        id="mission"
      />

      <ContentBlock
        type="left"
        title={ProductContent.title}
        content={ProductContent.text}
        icon="waving.svg"
        id="product"
      />
      <ContactFrom
        title={ContactContent.title}
        content={ContactContent.text}
        id="contact"
      />
    </Container>
    <Footer />
    </React.Fragment>
  );
};

export default PublicHomeContainer;
