import React, { lazy } from 'react';

import Footer from "../Components/Footer";
import Header from "../Components/Header";

import GlobalStyles from "../globalStyles";

const Container = lazy(() => import ("../common/Container"));

const LoginForm = lazy(() => import ("../Components/LoginForm"));

const selectFooter = () => {
    if (window.pageYOffset <= '150' && window.innerHeight > '700'){
      let footerJSX = <div style={{position: 'fixed',left: '0%',bottom: '0%', width: '100%'}}><Footer/></div>;
      return footerJSX;
    }
      else{
      return <Footer/>
    }
}

class LoginContainer extends React.Component {

 render() {
    return (
      <React.Fragment>
        <GlobalStyles />
          <Header showPortalHeader={true}/>
          <Container>
            <LoginForm
              id="login"
              icon="house.jpg"
            />
          </Container>
          {selectFooter()}
      </React.Fragment>
    );
  }
}

export default LoginContainer;
