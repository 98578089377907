import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import ApiWrapper from "../Utils/ApiWrapper";

const initialState = {
    status: 'idle',
    error: ''
};

export const fetchObservabletypesAsync = createAsyncThunk(
    'observableTypes/fetch',
    async (request) => {
        const response = await ApiWrapper.get('objekt-verwaltung-api', `/observabletypes`, {});
        return response;
    }
)

export const observabletypesSlice = createSlice({
    name: 'observableTypes',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchObservabletypesAsync.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(fetchObservabletypesAsync.fulfilled, (state, action) => {
            state.status = 'idle';
            state.instance = action.payload;
        })
        builder.addCase(fetchObservabletypesAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.instance = undefined;
            state.error = action.error.message;
        })
    }
});

export const selectObservableTypesStatus = (state) => state.observableTypes.status;
export const selectObservableTypes = (state) => state.observableTypes.instance;

export const selectObservableType = (id) => createSelector(
    selectObservableTypes,
    (observableTypes) => {
        return observableTypes.find(observableType => observableType.id === id)
    }
);

export default observabletypesSlice.reducer;
