import React, {useState} from 'react';
import SiteLayout from '../Components/Styled/SiteLayout';

import ApiWrapper from '../Utils/ApiWrapper';
import {Link} from 'react-router-dom';
import {PageHeader} from 'antd';


const PruefungsartEditContainer = (props) => {

  const [pruefungsarten, setPruefungsarten] = useState([]);

  const accountId = props.activeAccountId;
  const objektId = "0484f72b-3501-42ea-833d-5417a5af255f";

  const loadPruefungsarten = () => { // Aber wieso ObjektID in der URL?
    ApiWrapper.get('objekt-verwaltung-api', `/${accountId}/objekte/${objektId}/elemente/pruefungsarten/`).then(async (result) => {
      const body = JSON.parse(result.body);
      setPruefungsarten(body);
      console.log(body);
      console.log(pruefungsarten);
    }).catch(err => {
      console.log(err);
    });
  };


  return (
      <SiteLayout pageTitle="Überschrift" breadcumItems={[
          <Link to={`/uebersicht`}>Startseite</Link>,
          'Prüfungarten bearbeiten'
      ]}>

          <PageHeader
              title="Bearbeiten der Prüfungsarten"
              ghost={false}/>



      </SiteLayout>
    );

};
export default PruefungsartEditContainer;
