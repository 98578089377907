import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import SiteLayout from '../Components/Styled/SiteLayout';
import {Col, PageHeader, Row, Table} from 'antd';

import {PieChart, Pie, Cell, ResponsiveContainer} from "recharts";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

import {getActiveAccountName} from "../Utils/helpers";

import moment from 'moment';
import {useSelector} from "react-redux";
import {selectAllObservables, selectData, selectObservablesForObject} from "../app/dataSlice";

const OverviewContainer = (props) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        container: {
            display: "flex"
        },
        paper: {
            padding: theme.spacing(4),
            textAlign: "left",
            color: theme.palette.text.secondary,
            height: '100%'
        }
    }));

    const classes = useStyles();

    const COLORS = ['#052b54', '#b30e0e'];

    const [gesamtePruefungen, setGesamtePruefungen] = useState([]);
    const [offenePruefungen, setOffenePruefungen] = useState([]);
    const [erledigtePruefungen, setErledigtePruefungen] = useState([]);
    const [ueberfaelligePruefungenObjekte,setUeberfaelligePruefungenObjekte] = useState(0);
    const [objekteMitUeberfaelligenPruefungen,setObjekteMitUeberfaelligenPruefungen] = useState([]);

    const data = useSelector(selectData);

    const setupData = () => {
        const gesamtePruefungenListe = [];
        const offenePruefungenListe = [];
        const erledigtePruefungenListe = [];

        let objekteMitUeberfaelligenPruefungenListe = [];
        let anzahlUeberfaelligePruefungen = 0;

        for (const object of data.objekte) {
          if(object.ueberfaelligePruefungen > 0){
            objekteMitUeberfaelligenPruefungenListe.push(object);
          }
              for (const building of object.buildings) {
                  for (const parts of building.parts) {
                      for (let observable of parts.elemente) {
                          if (Array.isArray(observable.naechstePruefungen)) {
                              for (const pruefung of observable.naechstePruefungen) {
                                  if (moment(pruefung.naechstePruefung).format("YYYY-MM-DD") <= moment().add(30, 'days').format("YYYY-MM-DD") &&
                                      moment(pruefung.naechstePruefung).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")) {
                                      gesamtePruefungenListe.push(pruefung);
                                      offenePruefungenListe.push(pruefung);
                                  }
                              }
                          }
                          if (Array.isArray(observable.vergangenePruefungen)) {
                              for (const vergangenePruefung of observable.vergangenePruefungen) {
                                  //vergangenePruefung.resolvedAtClean = moment(vergangenePruefung.resolvedAt).format("DD.MM.YYYY");
                                  // Anzeige des Datums ohne Timestamp - im Deutschen Format;

                                  gesamtePruefungenListe.push(vergangenePruefung);
                                  erledigtePruefungenListe.push(vergangenePruefung);
                              }
                          }
                      }
                  }
              }

            anzahlUeberfaelligePruefungen = anzahlUeberfaelligePruefungen + object.ueberfaelligePruefungen;
        }
        setObjekteMitUeberfaelligenPruefungen(objekteMitUeberfaelligenPruefungenListe);
        setUeberfaelligePruefungenObjekte(anzahlUeberfaelligePruefungen)
        setGesamtePruefungen(gesamtePruefungenListe);
        setOffenePruefungen(offenePruefungenListe);
        setErledigtePruefungen(erledigtePruefungenListe);
    }

    useEffect(() => {
        setupData();
    }, [data])


    const renderfirstCard = () => {
        let firstCard;
        if (gesamtePruefungen.length === 0) {
            firstCard =
                <Paper className={classes.paper}>
                    <h2>Für diesen Zeitraum liegen noch keine Prüfungen vor.</h2>
                </Paper>;
            return firstCard;
        } else {
            firstCard =
                <Paper className={classes.paper}>
                    <h3 align="left" style={{fontSize: 20}}>Die nächsten 30 Tage:</h3>
                    <Row>
                        <Col span={12}>
                            <div style={{width: '100%', height: '100%'}}>
                                <ResponsiveContainer>
                                    <PieChart width="100%" height="100%">
                                        <Pie data={chartData} dataKey="value">
                                            {chartData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>

                            </div>
                        </Col>
                        <Col span={12}>
                            <p style={{fontSize: 24, color: '#AAA4A3'}}><b
                                style={{color: '#000000'}}>{gesamtePruefungen.length}</b> {gesamtePruefungenText}</p>
                            <p style={{fontSize: 24, color: '#AAA4A3'}}><b
                                style={{color: '#000000'}}>{offenePruefungen.length}</b> {offenePruefungenText}</p>
                            <p style={{fontSize: 24, color: '#AAA4A3'}}><b
                                style={{color: '#000000'}}>{erledigtePruefungen.length}</b> {erledigtePruefungenText}</p>
                        </Col>
                    </Row>
                </Paper>;
            return firstCard
        }
    }

    const columnsUeberfaellige = [
      {
          title: 'Objekt',
          dataIndex: 'titel',
          key: 'titel',
          render: (text, record) => <Link to={`/objekt/${record.id}`}>{text}</Link>
      },
      {
          title: 'Anzahl',
          dataIndex: 'ueberfaelligePruefungen',
          key: 'ueberfaelligePruefungen',
          width: '25%',
          sorter: (a, b) => a.ueberfaelligePruefungen - b.ueberfaelligePruefungen,
          defaultSortOrder: ['descend'],
      },
    ]

    const columnsErledigte = [
        {
            title: 'Objekt',
            dataIndex: 'objektTitel',
            key: 'objektTitel',
        },
        {
            title: 'Element',
            dataIndex: 'elementTitel',
            key: 'elementTitel',
        }, {
            title: 'Prüfung',
            dataIndex: 'titel',
            key: 'titel',
        }, {
            title: 'Prüfdatum',
            dataIndex: 'resolvedAt',
            key: 'resolvedAt',
            sorter: (a, b) => a.resolvedAt.localeCompare(b.resolvedAt),
            render: resolvedAt => {
                let resolvedAtClean = moment(resolvedAt).format("DD.MM.YYYY");
                return resolvedAtClean;
            }

        }, {
            title: 'Geprüft von',
            dataIndex: 'resolvedFrom',
            key: 'resolvedFrom',
        }
    ];

    const chartData = [
        {name: "erledigt", value: erledigtePruefungen.length},
        {name: "offen", value: offenePruefungen.length}
    ];

    let gesamtePruefungenText = '';
    if (gesamtePruefungen.length === '1') {
        gesamtePruefungenText = 'Prüfung,'
    } else {
        gesamtePruefungenText = 'Prüfungen,'
    }

    const offenePruefungenText = 'noch offen,'
    const erledigtePruefungenText = 'bereits erledigt.'

    let ueberfaelligePruefungenJSX = <React.Fragment>
        <h3 align="left" style={{fontSize: 20}}>Überfällige Prüfungen:</h3>
            <Table
                dataSource={objekteMitUeberfaelligenPruefungen}
                columns={columnsUeberfaellige}
                pagination={{
                    position: ["bottomCenter"],
                    showSizeChanger: false,
                    defaultPageSize: 3
                }}
            />
    </React.Fragment>;

    return (
        <SiteLayout pageTitle="Übersicht">
            <PageHeader
                title="Startseite"
                subTitle={`${getActiveAccountName()}`}
                ghost={false}/>
            <br/>
            <Grid container spacing={3} type="flex">
                <Grid
                    item xs={12} sm={6} md={6}
                    key='0'
                >
                    {renderfirstCard()}
                </Grid>


                <Grid
                    item xs={12} sm={6} md={6}
                    key='1'
                >
                    <Paper className={classes.paper}>
                    {ueberfaelligePruefungenObjekte === 0? <h2 align="center">Keine überfälligen Prüfungen vorhanden</h2>: ueberfaelligePruefungenJSX }

                    </Paper>
                </Grid>


                <Grid
                    item xs={12} sm={12} md={12}
                    key='2'
                >
                    <Paper className={classes.paper}>
                        <h3 align="left" style={{fontSize: 20}}>Zuletzt erledigt:</h3>

                        <Table
                            dataSource={erledigtePruefungen}
                            columns={columnsErledigte}
                            pagination={{
                                position: ["bottomCenter"],
                                showSizeChanger: false,
                                defaultPageSize: 4
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>

        </SiteLayout>
    );
};


export default OverviewContainer;
