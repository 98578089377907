import React, {useEffect} from 'react';
import {Result, List, Button} from 'antd';
import {useNavigate} from 'react-router-dom';


import {getPossibleAccounts, hasUserMultipleAccounts, setActiveAccount} from '../Utils/helpers';

const ChooseActiveAccountContainer = () => {

    const navigate = useNavigate();

    const possibleAccounts = getPossibleAccounts();

    const onClick = async (item) => {
        console.log(item);
        setActiveAccount(item);
        navigate('/uebersicht');
        //navigate(0);
    }

    useEffect(() => {
        if (!hasUserMultipleAccounts()) {
            setActiveAccount(possibleAccounts[0]);
            navigate('/uebersicht');
            //navigate(0);
        }
    }, []);

    return (
        <Result
            status="403"
            title="Bitte wähle das gewünschte Konto"
            subTitle="Damit du weitermachen kannst, musst du ein Konto auswählen."
        >
            <List
                itemLayout="horizontal"
                dataSource={possibleAccounts}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            title={<Button onClick={() => onClick(item.id)}>
                                {item.titel}
                            </Button>}
                        />
                    </List.Item>
                )}
            />
        </Result>
    );
};

export default ChooseActiveAccountContainer;
