import * as React from "react";
import {Form, Input} from "antd";
import {useEffect} from "react";

const {TextArea} = Input;

const Gebaeude = ({raumDetails, form}) => {

    useEffect(async () => {

        if (raumDetails) {
            console.log(raumDetails)
            form.setFieldsValue({
                name: raumDetails.name,
                description: raumDetails.description,
                id: raumDetails.id
            })
        } else {
            form.setFieldsValue({});
            //form_raume.resetFields();
        }
    }, [raumDetails]);



    return (<React.Fragment>
            <Form
                form={form}
            >
                <Form.Item
                    hidden={true}
                    name="id"
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{required: true, message: 'Name fehlt'}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Beschreibung"
                    name="description"
                >
                    <TextArea rows={4}/>
                </Form.Item>
            </Form>
        </React.Fragment>
    )
}

export default Gebaeude;
