import React from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Button, Form, notification, PageHeader} from 'antd';

import ApiWrapper from '../Utils/ApiWrapper';

import SiteLayout from '../Components/Styled/SiteLayout';

import Element from "../Components/Element";

import {getActiveAccountId} from "../Utils/helpers";
import {fetchDataAsync, selectObservable} from "../app/dataSlice";
import {useAppDispatch} from "../app/hooks";
import {useSelector} from "react-redux";

const ElementEditContainer = (props) => {
    const [form] = Form.useForm();

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const objektId = params.objektId;
    const buildingId = params.buildingId;
    const buildingpartId = params.buildingpartId;
    const elementId = params.elementId;

    const element = useSelector(selectObservable(elementId));

    const onOk = async () => {
        try {
            const values = await form.validateFields();
            console.log(values);
            ApiWrapper.put('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building/${buildingId}/part/${buildingpartId}/observable/${elementId}`, values).then(async (result) => {
                notification.success({
                    message: 'Änderung gespeichert!',
                    description: 'Die Änderung wurde gespeichert!',
                    placement: 'topRight',
                    duration: 5
                });
                dispatch(fetchDataAsync({activeAccountId: getActiveAccountId()}));
                navigate(`/objekt/${objektId}/building/${buildingId}/part/${buildingpartId}/element/${elementId}/details`, {replace: true});
            }).catch(err => {
                notification.error({
                    message: 'Änderung fehlgeschlagen!',
                    description: 'Die Änderung konnten nicht gespeichert!',
                    placement: 'topRight',
                    duration: 5
                });
                console.log(err);
            });
        } catch (err) {

            if (err.errorFields) {
                for (const errorField of err.errorFields) {
                    notification.error({
                        message: `Fehler in Feld ${errorField.name.join('')}`,
                        description: errorField.errors.join(', '),
                        placement: 'topRight',
                        duration: 5
                    });
                }
            } else {
                notification.error({
                    message: 'Fehler bei der Eingabe!',
                    description: 'Bei der Validierung der Eingabe kam es zu einem Fehler!',
                    placement: 'topRight',
                    duration: 5
                });
            }


            console.log(err);
        }
    }

    return (
        <SiteLayout pageTitle="Element Bearbeiten" breadcumItems={[
            <Link to={`/objekte`}>Account</Link>,
            <Link to={`/objekte`}>Objektübersicht</Link>,
            <Link to={`/objekt/${objektId}`}>Objekt</Link>,
            <Link
                to={`/objekt/${objektId}/building/${buildingId}/part/${buildingpartId}/element/${elementId}/details`}>Element</Link>,
            `Bearbeiten`
        ]}>

            <PageHeader
                ghost={false}
                title={`${element.titel}`}
            />

            <br/>

            <div>
                <Element form={form} objektId={objektId} element={element}/>

                <Button htmlType='button' style={{marginLeft: 8, marginRight: 8, marginBottom: 12}}>
                    <Link to={`/objekt/${objektId}`}>Abbrechen</Link>
                </Button>

                <Button type="primary" htmlType="submit" onClick={onOk}>
                    Änderung speichern
                </Button>
            </div>

        </SiteLayout>
    );
};
export default ElementEditContainer;
