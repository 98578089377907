import React, {useEffect, useState} from 'react';
import SiteLayout from '../Components/Styled/SiteLayout';
import {Link, useNavigate, useParams} from 'react-router-dom';

import ApiWrapper from '../Utils/ApiWrapper';

import {Button, Form, Input, notification, PageHeader, Select} from 'antd';
import {useAppDispatch} from "../app/hooks";
import {fetchDataAsync, selectObject} from "../app/dataSlice";
import {getActiveAccountId} from "../Utils/helpers";
import {useSelector} from "react-redux";

const {TextArea} = Input;
const {Option} = Select;

const ObjectEditContainer = (props) => {
    const [form] = Form.useForm();

    const navigate = useNavigate();
    const params = useParams();

    const [loading, setLoading] = useState(true);
    const objektId = params.objektId;

    const dispatch = useAppDispatch();
    const objektDetails = useSelector(selectObject(objektId));

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16,}
    };

    const tailLayout = {
        wrapperCol: {
            offset: 8,
            span: 16,
        },
    };

    const onFinish = (values) => {
        ApiWrapper.put('objekt-verwaltung-api', `/${props.activeAccountId}/objekt/${objektId}`, values).then(async (result) => {
            console.log(result);
            notification.success({
                message: 'Objekt bearbeitet!',
                description: 'Das Objekt wurde erfolgreich bearbeitet!',
                placement: 'topRight',
                duration: 10
            });
            dispatch(fetchDataAsync({activeAccountId: getActiveAccountId()}));
            navigate(`/objekt/${objektId}`);
        }).catch(err => {
            console.log(err);
        });
        console.log('Success:', 'ok');
    };

    const onFinishFailed = (errorInfo) => {
        notification.error({
            message: 'Fehler!',
            description: 'Sie haben nicht alle Pflichtfelder ausgefüllt',
            placement: 'topRight',
            duration: 10
        });
        console.log('Failed:', errorInfo);
    };

    useEffect(async () => {
        if (objektDetails && objektDetails.titel) {
            form.setFieldsValue({
                titel: `${objektDetails.titel}`,
                beschreibung: `${objektDetails.beschreibung}`,
                street: `${objektDetails.street}`,
                plz: `${objektDetails.plz}`,
                city: `${objektDetails.city}`
            });
            setLoading(false);
        }
    }, [objektDetails]);

    if (loading) {
        return null;
    }

    return (
        <SiteLayout pageTitle="Detailansicht Element" breadcumItems={[
            <Link to={`/uebersicht`}>Startseite</Link>,
            <Link to={`/objekte`}>Objektübersicht</Link>,
            <Link to={`/objekt/${objektId}`}>{objektDetails.titel}</Link>,
            'Bearbeiten'
        ]}>
            <PageHeader
                title={`Objekt bearbeiten: ${objektDetails.titel}`}
                ghost={false}
            >
            </PageHeader>
            <br/>
            <div style={{width: '80%', textAlign: 'left', marginLeft:'10%'}}>

                <Form form={form}
                      {...layout}
                      name="basic"
                      initialValues={{remember: false}}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      labelAlign="left"
                >

                    <br/>

                    <Form.Item
                        label="Titel"
                        name="titel"
                        rules={[{required: true, message: 'Titel fehlt'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Beschreibung"
                        name="beschreibung"
                    >
                        <TextArea rows={4}/>
                    </Form.Item>

                    <Form.Item
                        label="Straße + Hausnummer"
                        name="street"
                        rules={[{required: true, message: 'Straße fehlt'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Postleitzahl"
                        name="plz"
                        rules={[{required: true, message: 'Postleitzahl fehlt'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Ort"
                        name="city"
                        rules={[{required: true, message: 'Ort fehlt'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item {...tailLayout}>

                        <Button key="4"><Link to={`/objekt/${objektId}/editNotification`}>Benachrichtung
                            bearbeiten</Link></Button>
                        <Button htmlType='button' style={{marginLeft: 8, marginRight: 8, marginBottom: 12}}>
                            <Link to={`/objekt/${objektId}`}>Abbrechen</Link>
                        </Button>
                        <Button type="primary" htmlType="submit" style={{marginLeft: 8, marginRight: 8}}>
                            Speichern
                        </Button>
                    </Form.Item>
                </Form>
            </div>

        </SiteLayout>
    );
};


export default ObjectEditContainer;
