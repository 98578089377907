/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": process.env.REACT_APP_aws_cognito_identity_pool_id,
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": process.env.REACT_APP_aws_user_pools_id,
    "aws_user_pools_web_client_id": process.env.REACT_APP_aws_user_pools_web_client_id,
    "oauth": {},
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "verwaltung-dev",
            "region": "us-west-2"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": process.env.REACT_APP_backend_name,
            "endpoint": process.env.REACT_APP_backend_endpoint,
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
