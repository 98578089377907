import styled from 'styled-components';
import {Card, Collapse} from 'antd';
import {useState} from "react";

/** App Theme */
//import { colors } from '../../Themes/Colors';

const {Panel} = Collapse;

const StyledCollapse = styled(Collapse)
    `
    border: 0px;
`;

const StyledPanel = styled(Panel)
    `
  overflow: hidden;
  background: #fff;
  border: 0px;
  border-radius: 2px;

    & > .ant-collapse-header {
        
        color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    font-variant: tabular-nums;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: tabular-nums;
    font-variant-east-asian: normal;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    padding: 16px 16px !important;
    
    }

`;

const CollapsibleCard = ({children, ...props}) => {

    return (
        <StyledCollapse {...props} className="site-collapse-custom-collapse">
            <StyledPanel header={props.title} className="site-collapse-custom-panel">
                {children}
            </StyledPanel>
        </StyledCollapse>
    );

}

export default CollapsibleCard;
