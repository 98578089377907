import jwt_decode from 'jwt-decode';
import ApiWrapper from './ApiWrapper';
import {
    AUTH_USER_ACCOUNTS,
    AUTH_USER_ACTIVE_ACCOUNT,
    AUTH_USER_ACTIVE_ACCOUNT_NAME,
    AUTH_USER_TOKEN_KEY
} from './constants';


/**
 * helper method to validate  user token
 *
 * @param {*} token
 * @returns {boolean}
 */
export const validateToken = token => {
    if (!token) {
        return false;
    }
    try {
        const decodedJwt = jwt_decode(token);
        return decodedJwt.exp >= Date.now() / 1000;
    } catch (e) {
        return false;
    }
};

export const hasUserAccounts = () => {
    const userAccounts = JSON.parse(localStorage.getItem(AUTH_USER_ACCOUNTS));

    if (userAccounts && Array.isArray(userAccounts) && userAccounts.length >= 1) {
        return true;
    } else {
        return false;
    }
}

export const hasUserMultipleAccounts = () => {
    const userAccounts = JSON.parse(localStorage.getItem(AUTH_USER_ACCOUNTS));

    if (userAccounts && Array.isArray(userAccounts) && userAccounts.length > 1) {
        return true;
    } else {
        return false;
    }
}

export const getPossibleAccounts = () => {
    return JSON.parse(localStorage.getItem(AUTH_USER_ACCOUNTS));
}

export const setActiveAccount = (accountId) => {
    localStorage.setItem(AUTH_USER_ACTIVE_ACCOUNT, accountId);
}

export const setActiveAccountName = (name) => {
    localStorage.setItem(AUTH_USER_ACTIVE_ACCOUNT_NAME, name);
}

export const getActiveAccountName = () => {
    return localStorage.getItem(AUTH_USER_ACTIVE_ACCOUNT_NAME);
}

export const getActiveAccountId = () => {
    return localStorage.getItem(AUTH_USER_ACTIVE_ACCOUNT);
}

export const isAdmin = () => {

    try {
        const jwtToken = localStorage.getItem(AUTH_USER_TOKEN_KEY);
        const decodedJwt = jwt_decode(jwtToken);

        if (decodedJwt && decodedJwt["cognito:groups"] && Array.isArray(decodedJwt["cognito:groups"]) && decodedJwt["cognito:groups"].includes("admin")) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        console.error(e);
        return false;
    }


}

export const hasUserActiveAccount = () => {
    const activeAccountId = localStorage.getItem(AUTH_USER_ACTIVE_ACCOUNT);

    if (activeAccountId && activeAccountId !== "") {
        return true;
    } else {
        return false;
    }
}

export const translatePruefer = (shortform) => {
    var notwendigerPruefer;
    let converted = false;

    if (shortform === "jeder" || shortform === "jedem" || shortform === "Jeder" || shortform === "Jedem") {
        notwendigerPruefer = "Jeder";
        converted = true;
    }
    if (shortform === "sv" || shortform === "SV") {
        notwendigerPruefer = "Sachverständiger (SV)";
        converted = true;
    }
    if (shortform === "sk" || shortform === "SK") {
        notwendigerPruefer = "Sachkundiger (SK)";
        converted = true;
    }
    if (shortform === "bp" || shortform === "BP") {
        notwendigerPruefer = "Befähigte Person (bP)";
        converted = true;
    }
    if (shortform === "ef" | shortform === "EF") {
        notwendigerPruefer = "Elektrofachkraft";
        converted = true;
    }
    if (shortform === "vu" || shortform === "VU") {
        notwendigerPruefer = "Versicherer";
        converted = true;
    }
    if (shortform === "fb" || shortform === "FB") {
        notwendigerPruefer = "Fachbetrieb";
        converted = true;
    }
    if (shortform === "he" || shortform === "HE") {
        notwendigerPruefer = "Hersteller";
        converted = true;
    }
    if (shortform === "uv" || shortform === "UV") {
        notwendigerPruefer = "Unternehmer, Verantwortlicher vor Ort";
        converted = true;
    }
    if (shortform === "ep" || shortform === "EP") {
        notwendigerPruefer = "Elektrotechnisch unterwiesene Person";
        converted = true;
    }
    if (converted = false) {
      console.log(new Error('Fehler beim Konvertieren!'));
      notwendigerPruefer = "Fehler beim Konvertieren";
    }
    return notwendigerPruefer;
}
