import * as React from "react";
import {Form, Input, Select} from "antd";
import {useEffect, useState} from "react";
import ApiWrapper from "../Utils/ApiWrapper";
import {getActiveAccountId} from "../Utils/helpers";

const {TextArea} = Input;

const ChangeBuildingAndPart = ({element, form}) => {

    const [loading, setLoading] = useState(true);
    const [buildings, setBuildings] = useState([]);
    const [buildingparts, setBuildingparts] = useState([]);
    const [partValue, setPartValue] = useState('');

    const loadBuilding = async () => {
        const result = await ApiWrapper.get('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${element.objektId}/buildings`, {});
        setBuildings(result.map(x => {
            return {
                value: x.id,
                label: x.name
            }
        }));
    };

    useEffect(async () => {
        setLoading(true);
        await loadBuilding();
        setLoading(false);
        form.setFieldsValue({
          building:undefined,
          part:undefined
        });
    }, [element]);

    async function onChange(value) {
        console.log(value)

        const result = await ApiWrapper.get('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${element.objektId}/building/${value}/parts`, {});
        setBuildingparts(result.map(x => {
            return {
                value: x.id,
                label: x.name
            }
        }));
    }

    const clearPart = () => {
      if (partValue != ''){
        console.log("const clearPart")
        setPartValue('');
        form.setFieldsValue({part:undefined});
      }
    }

    const onPartChange = value => {
      setPartValue(value);
      form.setFieldsValue({part:value});
    }


    return (<React.Fragment>
            <Form form={form}>
                <Form.Item
                    label="Gebäude"
                    name="building"
                    rules={[{required: true, message: 'Bitte wählen Sie ein Gebäude aus.'}]}
                >
                    <Select
                        options={buildings}
                        onSelect={onChange}
                        onChange={clearPart}
                        placeholder="Bitte auswählen"
                    />
                </Form.Item>
                <Form.Item
                    label="Part"
                    name="part"
                    rules={[{required: true, message: 'Bitte wählen Sie einen Gebäudeteil aus.'}]}
                >
                    <Select
                        options={buildingparts}
                        onSelect={onChange}
                        placeholder="Bitte auswählen"
                        onChange= {onPartChange}
                        value = {partValue || undefined}
                        onClear = {clearPart}
                    />
                </Form.Item>

            </Form>
        </React.Fragment>
    )
}

export default ChangeBuildingAndPart;
