import reportWebVitals from './reportWebVitals';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';

/** Presentational */
import App from './Components/App';
import {GlobalStyles} from './global';

/** Amplify config */
import awsconfig from './aws-exports';

/** Service worker */
import * as serviceWorker from './serviceWorker';

import {I18nextProvider} from "react-i18next";
import i18n from "./translation";

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-5JNHTP3'
}

TagManager.initialize(tagManagerArgs)

/** Configure amplify */
Amplify.configure(awsconfig);

ReactDOM.render(
    <>
        <I18nextProvider i18n={i18n}>
            <GlobalStyles/>
            <App/>
        </I18nextProvider>

    </>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
