import {configureStore} from "@reduxjs/toolkit";
import dataSlice from "./dataSlice";
import observabletypesSlice from "./observabletypesSlice";

export const store = configureStore({
    reducer: {
        data: dataSlice,
        observableTypes: observabletypesSlice
    }
});
