import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import ApiWrapper from "../Utils/ApiWrapper";

const initialState = {
    accountId: '',
    status: 'idle',
    error: ''
};

export const fetchDataAsync = createAsyncThunk(
    'data/fetchData',
    async (request) => {
        const response = await ApiWrapper.get('objekt-verwaltung-api', `/accounts/${request.activeAccountId}/details`, {});
        return response;
    }
)

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        changeAccount: (state, action) => {
            state.accountId = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchDataAsync.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(fetchDataAsync.fulfilled, (state, action) => {
            state.status = 'idle';
            state.instance = action.payload;
        })
        builder.addCase(fetchDataAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.instance = undefined;
            state.error = action.error.message;
        })
    }
});

export const selectStatus = (state) => state.data.status;
export const selectData = (state) => state.data.instance;

export const selectObject = (id) => createSelector(
    selectData,
    (data) => data.objekte.find(object => object.id === id)
);

export const selectAllObservables = () => createSelector(
    selectData,
    (data) => {
        const observable = [];
        for (const object of data.objekte) {
            for (const building of object.buildings) {
                for (const parts of building.parts) {
                    for (let element of parts.elemente) {
                        observable.push(element);
                    }
                }
            }
        }
        return observable;
    }
);

export const selectObservablesForObject = (id) => createSelector(
    selectObject(id),
    (data) => {
        const observable = [];
        for (const building of data.buildings) {
            for (const parts of building.parts) {
                for (let element of parts.elemente) {
                    observable.push(element);
                }
            }
        }
        return observable;
    }
);

export const selectObservable = (id) => createSelector(
    selectData,
    (data) => {
        for (let objekt of data.objekte) {
            for (const building of objekt.buildings) {
                for (const part of building.parts) {
                    for (let element of part.elemente) {
                        if (element.id === id) {
                            return element;
                        }
                    }
                }
            }
        }
        return undefined;
    }
);

export const selectBuildingpartForObservable = (id) => createSelector(
    selectData,
    (data) => {
        for (let objekt of data.objekte) {
            for (const building of objekt.buildings) {
                for (const part of building.parts) {
                    for (let element of part.elemente) {
                        if (element.id === id) {
                            return part;
                        }
                    }
                }
            }
        }
        return undefined;
    }
);

export const selectPastAudit = (id) => createSelector(
    selectData,
    (data) => {
        for (let objekt of data.objekte) {
            for (const building of objekt.buildings) {
                for (const part of building.parts) {
                    for (let element of part.elemente) {
                        for (let vergangenePruefung of element.vergangenePruefungen) {
                            if (vergangenePruefung.id === id) {
                                return vergangenePruefung;
                            }
                        }
                    }
                }
            }
        }
        return {};
    }
);

export const {changeAccount} = dataSlice.actions;

export default dataSlice.reducer;
