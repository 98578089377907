import React, { useState /*,useEffect*/ } from 'react';

import { Auth } from 'aws-amplify';
import { Form, Input, Button, notification, Spin, Row, Col } from 'antd';
import Icon from '@ant-design/icons';

import SiteLayout from '../Components/Styled/SiteLayout';
import {colors} from "../Themes/Colors";
import FormWrapper from "../Components/Styled/FormWrapper";


const ChangeCurrentPasswordContainer = (props) => {

    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (values) => {

        let { oldPassword, newPassword, newPasswordConfirm } = values;

        if (newPassword !== newPasswordConfirm) {
            notification['error']({
                message: 'Die Passwörter stimmen nicht überein',
                placement: 'topRight',
                duration: 1.5
            });
            return;
        }

        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, oldPassword, newPassword);
            })
            .then(() => {
                notification.success({
                message: 'Success!',
                description: 'Das Passwort wurde erfolgreich geändert',
                placement: 'topRight'
            });}
                )
            .catch(err => {
                notification['error']({
                    message: 'Interner Fehler',
                    description: 'Upps - hier ist etwas schief gelaufen, versuche es bitte erneut. Sollte es weiterhin nicht funktionieren, melde den Fehler bitte an info@pruver.de',
                    placement: 'topRight'
                });

                setLoading(false);
            });

    };

    return (
    <SiteLayout pageTitle="Element Bearbeiten">
        <FormWrapper onFinish={onFinish} form={form}>
            <div className="text-center">
                <p>Für die Sicherheit deines Kontos benötigen wir zuerst dein altes Passwort</p>
            </div>
            <Form.Item name="oldPassword" rules={[
                {
                    required: true,
                    message: 'Bitte gib dein altes Passwort ein'
                }
            ]}>
                <Row>
                    <Col lg={24}>
                        <Input
                            type="password"
                            prefix={<Icon type="lock" style={{ color: colors.transparentBlack }} />}
                            placeholder="Altes Passwort"
                        />
                    </Col>
                </Row>
            </Form.Item>

            <React.Fragment>
                <p>Das Passwort muss mindestens 8 Stellen lang sein und enthalten: </p>
                <ul>
                    <li>mindestens eine Zahl (0-9),</li>
                    <li>mindestens ein Großbuchstabe,</li>
                    <li>mindestens ein Kleinbuchstabe.</li>
                </ul>
            </React.Fragment>

            <Form.Item name="newPassword" rules={[
                { required: true, message: 'Bitte geben Sie das neue Passwort ein' },
            ]} hasFeedback>
                <Row>
                    <Col lg={24}>
                        <Input.Password placeholder="Neues Passwort"/>
                    </Col>
                </Row>

            </Form.Item>

            <Form.Item
                name="newPasswordConfirm"
                dependencies={['newPassword']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Bitte bestätigen das Passwort'
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            const pass = getFieldValue('password');
                            if (!value || pass === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('Die Passwörter stimmen nicht überein!');
                        },
                    })
                ]}>
                <Row>
                    <Input
                      prefix={<Icon type="lock" style={{ color: colors.transparentBlack }} />}
                      type="password"
                      placeholder="Passwort bestätigen"
                    />
                </Row>
            </Form.Item>

            <Form.Item className="text-center">
                <Row>
                    <Col lg={24}>
                        <Button style={{ width: '100%' }} type="primary" htmlType="submit" className="login-form-button">
                            {loading ? (
                                <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
                            ) : (
                                'Neues Passwort vergeben'
                            )}
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </FormWrapper>


    </SiteLayout>
    );
};
export default ChangeCurrentPasswordContainer;
