import React, {useState, useEffect} from 'react';
import ApiWrapper from '../Utils/ApiWrapper';

import SiteLayout from '../Components/Styled/SiteLayout';

import {PageHeader, Button, Transfer, notification} from 'antd';
import {useParams} from "react-router-dom";


const UserManagementContainer = (props) => {

    const [userInAccounts, setUserInAccounts] = useState([]);
    const [user, setUser] = useState([]);
    const [allAccounts, setAllAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [accountsChanged, setAccountsChanged] = useState(false);

    const params = useParams();

    const userId = params.userId;

    const loadAccountForUser = async () => {
        // Alle Nutzer aus Cognitio laden

        try {
            const accountResult = await ApiWrapper.get('objekt-verwaltung-api', `/accounts`, {});
            const accountParsedBody = JSON.parse(accountResult.body);
            const accountParsedBodyAsArray = Array.isArray(accountParsedBody) ? accountParsedBody : [accountParsedBody];
            const mappedAccounts = accountParsedBodyAsArray.map((accountParsed) => {
                accountParsed.key = accountParsed.id;
                return accountParsed;
            })
            console.log(mappedAccounts);
            setAllAccounts(mappedAccounts);

            const result = await ApiWrapper.get('objekt-verwaltung-api', `/accounts/${userId}`, {});
            console.log(result)
            const parsedBody = JSON.parse(result.body);
            console.log(parsedBody);
            const parsedBodyAsArray = Array.isArray(parsedBody) ? parsedBody : [parsedBody];

            const userAccounts = [];
            for (const accountId of parsedBodyAsArray) {
                targetKeys.push(accountId);
                userAccounts.push(mappedAccounts.find(acc => acc.id === accountId));
            }
            setUserInAccounts(userAccounts);
        } catch (err) {
            console.log(err);
        }
    }

    const loadUserDetails = async () => {
        // Alle Nutzer aus Cognitio laden

        try {
            const result = await ApiWrapper.get('objekt-verwaltung-api', `/users/${userId}/details`, {});
            const parsedBody = JSON.parse(result.body);
            console.log(parsedBody.UserAttributes);
            result.email = parsedBody.UserAttributes.find(att => att.Name === "email").Value;
            setUser(result);
        } catch (err) {
            console.log(err);
        }
    }

    const handleChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);

        console.log('targetKeys: ', nextTargetKeys);
        console.log('direction: ', direction);
        console.log('moveKeys: ', moveKeys);
    };

    const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);

        console.log('sourceSelectedKeys: ', sourceSelectedKeys);
        console.log('targetSelectedKeys: ', targetSelectedKeys);
    };

    useEffect(async () => {
        await loadAccountForUser();
        await loadUserDetails();
        setLoading(false);
    }, []);

    const equalsIgnoreOrder = (a, b) => {
        if (a.length !== b.length) return false;
        const uniqueValues = new Set([...a, ...b]);
        for (const v of uniqueValues) {
            const aCount = a.filter(e => e === v).length;
            const bCount = b.filter(e => e === v).length;
            if (aCount !== bCount) return false;
        }
        return true;
    }

    const addedItems = () => {

        const addedItems = [];

        const keys = userInAccounts.map((accounts) => {
            return accounts.key;
        });

        for (const targetKey of targetKeys) {
            if (!keys.includes(targetKey)) {
                addedItems.push(targetKey);
            }
        }

        return addedItems;
    }

    const removedItems = () => {

        const removedItems = [];

        const keys = userInAccounts.map((accounts) => {
            return accounts.key;
        });

        for (const key of keys) {
            if (!targetKeys.includes(key)) {
                removedItems.push(key);
            }
        }

        return removedItems;
    }

    useEffect(async () => {
        const keys = userInAccounts.map((accounts) => {
            return accounts.key;
        })
        if (equalsIgnoreOrder(targetKeys, keys)) {
            setAccountsChanged(true);
        } else {
            setAccountsChanged(false);
        }

    }, [targetKeys]);

    const reset = () => {
        const keys = userInAccounts.map((accounts) => {
            return accounts.key;
        })
        setTargetKeys(keys);
    };

    const saveUser = async () => {

        try {
            const addedKeys = addedItems();
            const removedKeys = removedItems();

            for (const addedKey of addedKeys) {
                await addUserToAccount(addedKey);
            }

            for (const removedKey of removedKeys) {
                await removeUserFromAccount(removedKey);
            }

            await loadAccountForUser();

            notification.success({
                message: 'Nutzer wurde gespeichert!',
                description: 'Der Nutzer wurde erfolgreich hinzugefügt!',
                placement: 'topRight',
                duration: 5
            });
        } catch (e) {
            notification.error({
                message: 'Fehler!',
                description: 'Der Nutzer konnte nicht hinzugefügt werden!',
                placement: 'topRight',
                duration: 5
            });
        }


    };

    const addUserToAccount = async (accountKey) => {
        try {
            const result = await ApiWrapper.post('objekt-verwaltung-api', `/accounts/${accountKey}/user/`, {
                email: user.email
            });
            console.log(result)
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    const removeUserFromAccount = async (accountKey) => {
        try {
            const result = await ApiWrapper.del('objekt-verwaltung-api', `/accounts/${accountKey}/user/${userId}`);
            console.log(result)
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    return (
        <SiteLayout pageTitle="Überschrift">
            <PageHeader
                title={`Nutzer bearbeiten: ${user.email}`}
                ghost={false}
            >

            </PageHeader>
            <br/>
            <h2>Zuordnung zu Accounts</h2>
            <div>
                <Transfer
                    listStyle={{width: 800}}
                    showSearch={false}
                    dataSource={allAccounts}
                    titles={['Source', 'Target']}
                    targetKeys={targetKeys}
                    selectedKeys={selectedKeys}
                    onChange={handleChange}
                    onSelectChange={handleSelectChange}
                    render={item => item.titel}
                />
                <br/>
                <Button onClick={() => reset()} disabled={accountsChanged}>Zurücksetzten</Button>
                <Button onClick={() => saveUser()} disabled={accountsChanged} style={{marginLeft:5}}>Speichern</Button>
            </div>

        </SiteLayout>
    );
};

export default UserManagementContainer;
