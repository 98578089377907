import * as React from 'react';
import {Link, useParams,useNavigate} from 'react-router-dom';
import {DatePicker, Cascader, Select, Button, Form, Input, Checkbox, Tooltip, notification, Skeleton} from 'antd';
import { MinusOutlined, PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';

import * as Moment from "moment";
import de_DE from "antd/lib/date-picker/locale/de_DE";

import ApiWrapper from '../Utils/ApiWrapper';

import SiteLayout from '../Components/Styled/SiteLayout';
import {useEffect, useState} from "react";

const { Option } = Select;

const ElementCreateContainer = (props) => {

  const params = useParams();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const objektId = params.objektId;

  const [form] = Form.useForm();

  const [createAnother, setCreateAnother] = useState(false);

  let navigate = useNavigate();

  const onFinish = async (values) => {

    try {
      await ApiWrapper.post('objekt-verwaltung-api', `/${props.activeAccountId}/objekte/${objektId}/elemente`, values);

      if (!createAnother) {
        notification.success({
          message: 'Element angelegt!',
          description:'Das Element wurde erfolgreich angelegt!',
          placement: 'topRight',
          duration: 5
        });
        navigate(`/objekt/${objektId}`);
      } else {
        form.resetFields();
        notification.success({
          message: 'Element angelegt',
          description: 'Sie können jetzt ein weiteres Element anlegen!',
          placement: 'topRight',
          duration: 5
        });
      }
    } catch(err) {
      notification.error({
        message: 'Fehler!',
        description: 'Das Element konnte nicht angelegt werden.',
        placement: 'topRight',
        duration: 5
      });
      console.log(err);
    }

  };

  const onFinishFailed = (errorInfo) => {
    notification.error({
        message: 'Fehler!',
        description: 'Sie haben nicht alle Pflichtfelder ausgefüllt',
        placement: 'topRight',
        duration: 5
    });
    console.log('Failed:', errorInfo);
  };

  const [options, setOptions] = useState([]);

  const loadOptions = () => {

    ApiWrapper.get('objekt-verwaltung-api', `/${props.activeAccountId}/objekte/${objektId}/elemente/pruefungsarten`, {}).then((result) => {
      console.log(result);
      const parsedBody = JSON.parse(result.body);
      const parsedBodyAsArray = Array.isArray(parsedBody) ? parsedBody : [parsedBody];

      const tempOptions = [];

      for (const pruefungsart of parsedBodyAsArray) {
        const tempOpt = {
          value: pruefungsart.titel,
          label: pruefungsart.titel,
          id: pruefungsart.id
        };
        tempOptions.push(tempOpt);
      }

      console.log(parsedBody);
      setOptions(tempOptions);
    }).catch(err => {
      console.log(err);
    });

  }

  useEffect(async () => {
    loadOptions();
  }, []);

  const onCheckboxChange = async (e) => {
    await setCreateAnother(e.target.checked);
  };

  // function handleAreaClick(e, label, option) {
  //   e.stopPropagation();
  //   console.log('clicked', label, option);
  // }

  const displayRender = (labels, selectedOptions) =>
    labels.map((label, i) => {
      const option = selectedOptions[i];
      if (i === labels.length - 1) {
        return (
          <span key={option.value}>
          {label} {option.code}
        </span>
        );
      }
      return <span key={option.value}>{label} / </span>;
    });


  return (
      options.length <= 0 ? <Skeleton/> :
    <SiteLayout pageTitle="Element Anlegen" breadcumItems={[
    <Link to={`/objekte`}>Account</Link>,
    <Link to={`/objekt/${objektId}`}>Objekt</Link>,
    ]}>

      <Form
      {...layout}
      name="basic"
      form={form}
      initialValues={{ remember: false }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      scrollToFirstError={true}
      autoComplete="off"
    >
    <Form.Item
        label="Element Kategorie"
        name="kategorie"
        rules={[{ required: true, message: 'Element Kategorie fehlt' }]}
      >
        <Cascader
          options={options}
          placeholder="Bitte auswählen"
          displayRender={displayRender}
        />
      </Form.Item>
      <Form.Item
        label="Titel"
        name="titel"
        rules={[{ required: true, message: 'Titel fehlt' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Beschreibung"
        name="beschreibung"
        rules={[{ required: true, message: 'Beschreibung fehlt' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Etage / Raum" rules={[{ required: true }]}>
        <Input.Group compact>

          <Form.Item
            name= "etage"
            rules={[{ required: true, message: 'Etage fehlt' }]}
          >
            <Input placeholder="Etage"/>
          </Form.Item>

          <Form.Item
            name= "raum"
            rules={[{ required: true, message: 'Raum fehlt' }]}
          >
            <Input placeholder="Raum"/>
          </Form.Item>

        </Input.Group>
      </Form.Item>

      <Form.Item
        label="Geräte Id"
        name="geraeteId"
        rules={[{ required: false }]}
      >
        <Input
      placeholder="Geräte Id"
      suffix={
        <Tooltip title="Extra Information">
          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
        </Tooltip>
      }
    />
      </Form.Item>
      <Form.Item
        label="Einbaudatum"
        name="einbauDatum"
        rules={[{ required: false }]}
      >
        <DatePicker locale={de_DE} placeholder="Datum" suffix={
        <Tooltip title="Wann wurde das Gerät eingebaut?">
          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
        </Tooltip>
      }/>
      </Form.Item>
      <Form.List name="pruefungen">
        {(fields, { add, remove }) => (
          <React.Fragment>
            {fields.map(field => (
              <React.Fragment key={field.key} align="baseline">
              <hr/>
                <Form.Item
                  {...field}
                  label="Titel"
                  name={[field.name, 'titel']}
                  fieldKey={[field.fieldKey, 'titel']}
                  rules={[{ required: true, message: 'Titel fehlt' }]}
                >
                  <Input placeholder="Titel" />
                </Form.Item>
                <Form.Item
                  {...field}
                  label="Prüfung von"
                  name={[field.name, 'pruefungVon']}
                  fieldKey={[field.fieldKey, 'pruefungVon']}
                  rules={[{ required: true, message: 'Prüfer fehlt' }]}
                >
                  <Select
                    placeholder="Prüfung von"
                  >
                    <Option value="jeder">Jeder</Option>
                    <Option value="sv">Sachverständiger (SV)</Option>
                    <Option value="sk">Sachkundiger (SK)</Option>
                    <Option value="bp">Befähigte Person (bP)</Option>
                    <Option value="ef">Elektrofachkraft</Option>
                    <Option value="vu">Versicherer</Option>
                    <Option value="fb">Fachbetrieb</Option>
                    <Option value="he">Hersteller</Option>
                    <Option value="uv">Unternehmer, Verantwortlicher vor Ort</Option>
                    <Option value="ep">Elektrotechnisch unterwiesene Person</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Interval">
                  <Input.Group compact>
                    <Form.Item
                      name={[field.name, 'interval', 'art']}
                      noStyle
                      rules={[{ required: true, message: 'Art fehlt' }]}
                    >
                    <Select placeholder="Bitte wählen" className="select-after">
                      <Option value="pruefung">Prüfung alle</Option>
                      <Option value="tausch">Austausch alle</Option>
                    </Select>
                    </Form.Item>
                    <Form.Item
                      name={[field.name, 'interval', 'wert']}
                      noStyle
                      rules={[{ required: true, message: 'Anzahl fehlt' }]}
                    >
                      <Input style={{ width: '50%' }} placeholder="XXX" />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, 'interval', 'einheit']}
                      noStyle
                      rules={[{ required: true, message: 'Einheit fehlt' }]}
                    >
                      <Select defaultValue="monate" className="select-after">
              <Option value="tage">Tage</Option>
              <Option value="monate">Monate</Option>
              <Option value="jahre">Jahre</Option>
            </Select>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item
                  {...field}
                  label="Letzte Prüfung"
                  name={[field.name, 'letztePruefung']}
                  fieldKey={[field.fieldKey, 'letztePruefung']}
                  rules={[{ required: false }]}
                >
                <DatePicker locale={de_DE} placeholder="Datum"/>
                </Form.Item>
                <Form.Item
                  {...field}
                  label="Beschreibung"
                  name={[field.name, 'beschreibung']}
                  fieldKey={[field.fieldKey, 'beschreibung']}
                  rules={[{ required: false }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item label=" " >
              <Button type="dashed"  style={{ width: '60%' }} onClick={() => remove(field.name)} block icon={<MinusOutlined />}>
                Prüfung löschen
              </Button>
            </Form.Item>
              </React.Fragment>
            ))}
            <Form.Item label=" ">

              <Button type="dashed"  style={{ width: '60%' }}onClick={() => add()} block icon={<PlusOutlined />}>
                Prüfung hinzufügen
              </Button>
            </Form.Item>
          </React.Fragment>
        )}
      </Form.List>
      <Form.Item {...tailLayout} name="createAnother">
        <Checkbox checked={createAnother} onChange={onCheckboxChange}>Weiteres Element anlegen?</Checkbox>
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button htmlType='button' style={{marginLeft: 8, marginRight:8,marginBottom:12}} >
              <Link to={`/objekt/${objektId}`}>
                  Abbrechen
              </Link>
        </Button>
        <Button type="primary" htmlType="submit">
          Element anlegen
        </Button>
      </Form.Item>

    </Form>
    </SiteLayout>
  );
};

export default ElementCreateContainer;
