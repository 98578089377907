import React, { lazy } from 'react';

import Footer from "../Components/Footer";
import Header from "../Components/Header";

import GlobalStyles from "../globalStyles";


const Container = lazy(() => import ("../common/Container"));

const PasswordResetForm = lazy(() => import ("../Components/PasswordResetForm"));

class ForgotPasswordContainer extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <GlobalStyles />
          <Header showPortalHeader={true} />
          <Container>
            <PasswordResetForm
              id="login"
              icon="product-launch.svg"
            />

          </Container>
          <div style={{position: 'fixed',left: '0%',bottom: '0%', width: '100%'}}>
              <Footer/>
          </div>
      </React.Fragment>
    );
  }
}

export default ForgotPasswordContainer;
