import React, {Suspense} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

/** Container elements */
import LoginContainer from '../Containers/LoginContainer';
import ForgotPasswordContainer from '../Containers/ForgotPasswordContainer';
import PasswordResetContainer from '../Containers/PasswordResetContainer';
import ObjectListContainer from '../Containers/ObjectListContainer';
import ObjectCreateContainer from '../Containers/ObjectCreateContainer';
import ObjectEditContainer from '../Containers/ObjectEditContainer';
import ObjectDetailsContainer from '../Containers/ObjectDetailsContainer';
import ObjectPruefungenDokumentierenContainer from '../Containers/ObjectPruefungenDokumentierenContainer';
import NotFoundContainer from '../Containers/NotFoundContainer';
import UsersContainer from '../Containers/UsersContainer';
import AddUserToAccountContainer from '../Containers/AddUserToAccountContainer';
import ChooseActiveAccountContainer from '../Containers/ChooseActiveAccountContainer';
import ElementCreateContainer from '../Containers/ElementCreateContainer';
import ElementCreateGuidedContainer from '../Containers/ElementCreateGuidedContainer';
import ElementDetailsContainer from '../Containers/ElementDetailsContainer';
import ElementEditContainer from '../Containers/ElementEditContainer';
import PublicHomeContainer from '../Containers/PublicHomeContainer';
import ImpressumContainer from '../Containers/ImpressumContainer';
import VoreinstellungenContainer from '../Containers/VoreinstellungenContainer';
import ViewPruefungenContainer from '../Containers/ViewPruefungenContainer'
import ChangeCurrentPasswordContainer from '../Containers/ChangeCurrentPasswordContainer'
import OverviewContainer from '../Containers/OverviewContainer'
import PruefungsartEditContainer from '../Containers/PruefungsartEditContainer'
import UserManagementContainer from '../Containers/UserManagementContainer'
import UserEditContainer from '../Containers/UserEditContainer'
import PruefungsDetailContainer from '../Containers/PruefungsDetailContainer'
import NotificationSettingContainer from '../Containers/NotificationSettingContainer'
import NotificationUserEditContainer from '../Containers/NotificationUserEditContainer'
import MfaSettingContainer from '../Containers/MfaSettingContainer'

/** private route element */
import PrivateRoute from './PrivateRoute';
import PublicRoutes from './PublicRoutes';
import DatenschutzContainer from "../Containers/DatenschutzContainer";

const AppRouter = () => {

    return (
        <Suspense fallback={null}>
            <Router>
                <Routes>

                    <Route exact={true} path="/" element={<PublicHomeContainer/>}/>
                    <Route exact={true} path="/impressum" element={<ImpressumContainer/>}/>
                    <Route exact={true} path="/datenschutz" element={<DatenschutzContainer/>}/>

                    <Route exact={true} path="/login"
                           element={<PublicRoutes onlyLogout={true}><LoginContainer/></PublicRoutes>}/>
                    <Route exact={true} path="/reset-password" element={<PasswordResetContainer/>}/>
                    <Route exact={true} path="/forgot-password" element={<ForgotPasswordContainer/>}/>

                    <Route exact={true} path="/uebersicht"
                           element={<PrivateRoute component={OverviewContainer}></PrivateRoute>}/>
                    <Route exact={true} path="/objekte"
                           element={<PrivateRoute component={ObjectListContainer}></PrivateRoute>}/>

                    <Route exact={true} path="/objekt/create"
                           element={<PrivateRoute component={ObjectCreateContainer} onlyAdmin={true}></PrivateRoute>}/>

                    <Route exact={true} path="/objekt/:objektId/"
                           element={<PrivateRoute component={ObjectDetailsContainer}></PrivateRoute>}/>

                    <Route exact={true} path="/objekt/:objektId/edit"
                           element={<PrivateRoute component={ObjectEditContainer} onlyAdmin={true}></PrivateRoute>}/>
                    <Route exact={true} path="/objekt/:objektId/editNotification"
                           element={<PrivateRoute component={NotificationUserEditContainer} onlyAdmin={false}></PrivateRoute>}/>
                    <Route exact={true} path="/objekt/:objektId/pruefungen/dokumentieren"
                           element={<PrivateRoute component={ObjectPruefungenDokumentierenContainer}></PrivateRoute>}/>
                    <Route exact={true} path="/objekt/:objektId/elemente/anlegen"
                           element={<PrivateRoute component={ElementCreateContainer} onlyAdmin={true}></PrivateRoute>}/>
                    <Route exact={true} path="/objekt/:objektId/elemente/einrichten"
                           element={<PrivateRoute component={ElementCreateGuidedContainer} onlyAdmin={true}></PrivateRoute>}/>

                    <Route exact={true} path="/objekt/:objektId/building/:buildingId/part/:buildingpartId/element/:elementId/details"
                           element={<PrivateRoute component={ElementDetailsContainer}></PrivateRoute>}/>
                    <Route exact={true} path="/objekt/:objektId/building/:buildingId/part/:buildingpartId/element/:elementId/edit"
                           element={<PrivateRoute component={ElementEditContainer} onlyAdmin={true}></PrivateRoute>}/>
                    <Route exact={true} path="/objekt/:objektId/building/:buildingId/part/:buildingpartId/element/:elementId/pruefung/:pruefungsId/details"
                           element={<PrivateRoute component={PruefungsDetailContainer}></PrivateRoute>}/>

                    <Route exact={true} path="/admin/benutzer/:userId"
                           element={<PrivateRoute component={UserEditContainer} onlyAdmin={true}></PrivateRoute>}/>
                    <Route exact={true} path="/admin/usermanagement"
                           element={<PrivateRoute component={UserManagementContainer}
                                                  onlyAdmin={true}></PrivateRoute>}/>

                    <Route exact={true} path="/account/users"
                           element={<PrivateRoute component={UsersContainer}></PrivateRoute>}/>
                    <Route exact={true} path="/account/wechseln"
                           element={<PrivateRoute component={ChooseActiveAccountContainer}></PrivateRoute>}/>

                    <Route exact={true} path="/benutzer/hinzufügen"
                           element={<PrivateRoute component={AddUserToAccountContainer}></PrivateRoute>}/>

                    <Route exact={true} path="/:accountId/pruefungen"
                           element={<PrivateRoute component={ViewPruefungenContainer}></PrivateRoute>}/>

                    <Route exact={true} path="/pruefungsart"
                           element={<PrivateRoute component={PruefungsartEditContainer}></PrivateRoute>}/>
                    <Route exact={true} path="/benutzer/passwort-aendern"
                           element={<PrivateRoute component={ChangeCurrentPasswordContainer}></PrivateRoute>}/>
                    <Route exact={true} path="/benutzer/mfa-aendern"
                           element={<PrivateRoute component={MfaSettingContainer}></PrivateRoute>}/>

                    <Route exact={true} path="/voreinstellung"
                           element={<PrivateRoute component={VoreinstellungenContainer}></PrivateRoute>}/>

                    <Route exact={true} path="/settings/notifications"
                           element={<PrivateRoute component={NotificationSettingContainer}></PrivateRoute>}/>

                    <Route element={<NotFoundContainer/>}/>
                </Routes>
            </Router>
        </Suspense>
    );

}

export default AppRouter;
