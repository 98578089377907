import React from 'react';

import SiteLayout from '../Components/Styled/SiteLayout';

import { PageHeader, Card } from 'antd';

const VoreinstellungenContainer = () => {

  return (
    <SiteLayout pageTitle="Voreinstellungen" breadcumItems={['Account', 'Objekt']}>
      <PageHeader
          ghost={false}
          title={'Voreinstellungen'}
          subTitle="Details"
      ></PageHeader>
      <Card title="Details" bordered={true} style={{ 'text-align': 'left', 'margin': '10px 0' }}>
        <p>
          Hier sollen später einmal die verschiedenen Vorstellungen für die einzelnen Elementtypen gepflegt werden können.
        </p>
        Mögliche Felder <br/>
        ID (UUID) <br/>
        ID (als laufende Nr?) <br/>
        Name <br/>
        Beschbreibung <br/>
        Rechtsgrundlage (evtl. aufgeteilt)<br/>
        Prüfungsintervalle <br/>
        Austauschintervalle <br/>
        Austauschintervalle <br/>
        Letzte Änderung <br/>
        Parent (um eine Hirachie aufbauen zu könne) <br/>

      </Card>

    </SiteLayout>
  );
};

export default VoreinstellungenContainer;
