import React, {useEffect, useState} from 'react';

import SiteLayout from '../Components/Styled/SiteLayout';

import {PageHeader, Card, Table, Button, Form, Input, notification, Select} from 'antd';
import ApiWrapper from "../Utils/ApiWrapper";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools"

const {Option} = Select;

const NotificationSettingContainer = () => {

    const [emailtemplates, setEmailtemplates] = useState([]);
    const [emailtemplate, setEmailtemplate] = useState({});

    const [form] = Form.useForm();
    const [formTemplate] = Form.useForm();

    const loadEmailTemplates = () => {
        ApiWrapper.get('objekt-verwaltung-api', `/admin/settings/notification/email/templates`, {}).then((result) => {
            console.log(result)
            const names = result.TemplatesMetadata.map(template => {return {
                value: template.Name,
                label: template.Name
            }});
            console.log(names)
            setEmailtemplates(names);
        }).catch(err => {
            console.log(err);
        })
    }

    const loadCurrentEmailTemplate = (templateName) => {
        ApiWrapper.get('objekt-verwaltung-api', `/admin/settings/notification/email/template/${templateName}`, {}).then((result) => {
            console.log(result)
            setEmailtemplate(result.Template);
            formTemplate.setFieldsValue({
                subject: result.Template.SubjectPart
            })
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(async () => {
        await loadEmailTemplates();
    }, []);

    const onFinish = (values) => {
        console.log(values);
        const params = {
            Template: {
                TemplateName: values.TemplateName, /* required */
                HtmlPart: 'HTML_CONTENT',
                SubjectPart: 'SUBJECT_LINE',
                TextPart: 'TEXT_CONTENT'
            }
        };
        ApiWrapper.post('objekt-verwaltung-api', `/admin/settings/notification/email/template`, params).then(async (result) => {
            console.log(result);
            notification.success({
                message: 'Objekt bearbeitet!',
                description: 'Das Objekt wurde erfolgreich bearbeitet!',
                placement: 'topRight',
                duration: 10
            });
            loadEmailTemplates();
        }).catch(err => {
            console.log(err);
        });

    }

    const onChange = (value) => {
        console.log(value)
        loadCurrentEmailTemplate(value)
    }

    const onChangeHtml = (value) => {
        emailtemplate.HtmlPart = value;
        setEmailtemplate(emailtemplate);
    }
    const onChangeText = (value) => {
        emailtemplate.TextPart = value;
        setEmailtemplate(emailtemplate);
    }
    const saveTemplate = (value) => {
        console.log(value)
        emailtemplate.SubjectPart = value.subject
        console.log(emailtemplate)
        ApiWrapper.put('objekt-verwaltung-api', `/admin/settings/notification/email/template/${emailtemplate.TemplateName}`, {
            Template: emailtemplate
        }).then(async (result) => {
            console.log(result);
            notification.success({
                message: 'Objekt bearbeitet!',
                description: 'Das Objekt wurde erfolgreich bearbeitet!',
                placement: 'topRight',
                duration: 10
            });
            loadEmailTemplates();
        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <SiteLayout pageTitle="Voreinstellungen" breadcumItems={['Account', 'Objekt']}>
            <PageHeader
                ghost={false}
                title={'Voreinstellungen'}
                subTitle="Details"
            ></PageHeader>
            <Card title="Details" bordered={true}>

                <Form form={form} onFinish={onFinish}>
                    <Form.Item
                        label="Name"
                        name="TemplateName"
                        rules={[{required: true, message: 'TemplateName fehlt'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Button type="primary" htmlType="submit" style={{marginLeft: 8, marginRight: 8}}>
                        Speichern
                    </Button>
                </Form>

                <Select
                    options={emailtemplates}
                    onSelect={onChange}
                    placeholder="Bitte auswählen"
                />
                <br/>

                <Form form={formTemplate} onFinish={saveTemplate}>

                    Überschrift:
                    <Form.Item
                        label="Name"
                        name="subject"
                        rules={[{required: true, message: 'TemplateName fehlt'}]}
                    >
                        <Input/>
                    </Form.Item>
                    HTML-Content:
                    <AceEditor
                        mode="html"
                        theme="monokai"
                        onChange={onChangeHtml}
                        name="editorHtml"
                        value={emailtemplate.HtmlPart}
                        highlightActiveLine={true}
                        editorProps={{ $blockScrolling: true }}
                        width="100%"
                        setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            showLineNumbers: true,
                            enableSnippets: true
                        }}
                    />
                    Fallback Text:
                    <AceEditor
                        mode="markdown"
                        theme="monokai"
                        onChange={onChangeText}
                        name="editorText"
                        highlightActiveLine={true}
                        value={emailtemplate.TextPart}
                        editorProps={{ $blockScrolling: true }}
                        width="100%"
                        setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            showLineNumbers: true,
                            enableSnippets: true
                        }}
                    />
                    <Button type="primary" htmlType="submit" style={{marginLeft: 8, marginRight: 8}}>
                        Speichern
                    </Button>
                </Form>



            </Card>

        </SiteLayout>
    );
};

export default NotificationSettingContainer;
