import React, {lazy} from "react";


import Footer from "../Components/Footer";
import Header from "../Components/Header";

const Container = lazy(() =>
    import ("../common/Container"));
const ScrollToTop = lazy(() =>
    import ("../common/ScrollToTop"));

  const selectFooter = () => {
      if (window.pageYOffset <= '150' && window.innerHeight > '400'){
        let footerJSX = <div style={{position: 'fixed',left: '0%',bottom: '0%', width: '100%'}}><Footer/></div>;
        return footerJSX;
      }
        else{
        return <Footer/>
      }
  }


const ImpressumContainer = () => {

    return (
        <React.Fragment>
            <Header showPortalHeader={true}/>
            <Container>
                <ScrollToTop/>
                <h2>Impressum</h2>

                <h4>Angaben gem&auml;&szlig; &sect; 5 TMG</h4>
                <p>Mario Cla&szlig;en<br/>
                    Ringstr. 28<br/>
                    50765 K&ouml;ln</p>

                <h4>Kontakt</h4>
                <p>
                    E-Mail: info@pruver.de</p>

                <h4>Redaktionell verantwortlich</h4>
                <p>Mario Cla&szlig;en<br/>
                    Ringstr. 28<br/>
                    50765 K&ouml;ln</p>
            </Container>
              {selectFooter()}
        </React.Fragment>
    );
};

export default ImpressumContainer;
