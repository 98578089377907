import React, {useState, useEffect} from 'react';

import {Button, Result, Skeleton} from 'antd';
import SiteLayout from '../Components/Styled/SiteLayout';
import {useAppDispatch} from "../app/hooks";
import {fetchDataAsync, selectData, selectStatus} from "../app/dataSlice";
import {useSelector} from "react-redux";
import {fetchObservabletypesAsync} from "../app/observabletypesSlice";

const ContextWrapper = ({component: Component, activeAccountId}) => {


    const dispatch = useAppDispatch();

    useEffect(async () => {
        if (activeAccountId && activeAccountId !== "") {
            dispatch(fetchDataAsync({activeAccountId}))
            dispatch(fetchObservabletypesAsync())
        }
    }, [dispatch, activeAccountId]);

    const state = useSelector(selectStatus);
    const data = useSelector(selectData);

    if (state === 'loading') {
        return <SiteLayout><Skeleton/></SiteLayout>;
    }

    if (state === 'failed') {
        return <Result
            status="500"
            title="Fehler"
            subTitle="Leider gab es beim Laden der Daten einen Fehler."
        />;
    }

    if (data == null) {
        return <SiteLayout>Daten nicht verfügbar</SiteLayout>;
    }

    return (
        <Component activeAccountId={activeAccountId} accountData={data} reloadData={undefined}/>
    );

};

export default ContextWrapper;
