import * as React from 'react';
import {Provider} from "react-redux";

/** Components */
import AppRouter from '../Navigation/Routes';
import {store} from "../app/store";

/** Ant design stylesheet */
import 'antd/dist/antd.css';


const App = () => {
    return (
        <Provider store={store}>
            <AppRouter />
        </Provider>

    );
};

export default App;
