//import React, { useState, useEffect } from 'react';
import ApiWrapper from '../Utils/ApiWrapper';

import SiteLayout from '../Components/Styled/SiteLayout';

import { Form, Input, Button, notification } from 'antd';
import {useNavigate} from "react-router-dom";

const UsersContainer = (props) => {

  let navigate = useNavigate();

  const onFinish = (values) => {
    ApiWrapper.post('objekt-verwaltung-api', `/accounts/${props.activeAccountId}/user/`, values).then((result) => {
      console.log(result);
      if (result.statusCode === 200) {
        notification.success({
          message: 'Nutzer hinzugefügt!',
          description: 'Der Nutzer wurde erfolgreich hinzugefügt!',
          placement: 'topRight',
          duration: 5
        });
        const { history } = props;
        navigate(`/benutzer`);
      }
      else if (result.statusCode === 406) {
        notification.error({
          message: 'Fehler',
          description: 'Der Nutzer ist nicht vorhanden',
          placement: 'topRight'
        });
      }
      else {
        notification.error({
          message: 'Fehler',
          description: 'Es gab einen technischen Fehler',
          placement: 'topRight'
        });
      }

    }).catch(err => {
      console.error(err);
      notification.error({
        message: 'Fehler',
        description: 'Es gab einen technischen Fehler',
        placement: 'topRight'
      });
    });
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <SiteLayout pageTitle="Überschrift">

<Form
      name="basic"
      initialValues={{ remember: false }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="E-Mail"
        name="email"
        rules={[{ required: true, message: 'Bitte gib eine Email-Adresse ein' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Nutzer hinzufügen
        </Button>
      </Form.Item>

    </Form>

    </SiteLayout>
  );
};

export default UsersContainer;
