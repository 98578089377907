import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {Link, useNavigate, useParams} from 'react-router-dom';
import ApiWrapper from '../Utils/ApiWrapper';

import SiteLayout from '../Components/Styled/SiteLayout';

import de_DE from "antd/lib/date-picker/locale/de_DE";

import {
    Button,
    Checkbox,
    DatePicker,
    Descriptions,
    Divider,
    Form,
    Input,
    Modal,
    notification,
    Popconfirm,
    Steps,
    Table,
    Typography,
    Upload
} from 'antd';
import {InboxOutlined} from '@ant-design/icons';
import {getActiveAccountId, translatePruefer} from "../Utils/helpers";
import {useAppDispatch} from "../app/hooks";
import {fetchDataAsync, selectObject, selectObservablesForObject} from "../app/dataSlice";
import {useSelector} from "react-redux";

const {Step} = Steps;
const {Dragger} = Upload;

const ObjectPruefungenDokumentierenContainer = (props) => {

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };
    const tailLayout = {
        wrapperCol: {offset: 8, span: 16},
    };

    const [form] = Form.useForm();
    const [formTabelle] = Form.useForm();
    const [formTabellemodal] = Form.useForm();
    const {TextArea} = Input;

    const [abgeschlossenePruefungen, setAbgeschlossenePruefungen] = useState([]);
    const [loading, setLoading] = useState(true);
    const [objektPruefungen, setObjektPruefungen] = useState([]);
    const [current, setCurrent] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [targetKeys, setTargetKeys] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [uploadingKey, setUploadingKey] = useState('');
    const [fileList, setFileList] = useState([]);
    const [checked, setChecked] = useState(false);
    const [uploadFileModalVisible, setUploadFileModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);

    const params = useParams();

    const objektId = params.objektId;

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const objektDetails = useSelector(selectObject(objektId));

    const onFinish = (values) => {
        //console.log('Success:', values);

        let pruefungen = objektPruefungen.filter(pruefung => selectedRowKeys.includes(pruefung.key));

        if (pruefungen.length > 0) {
            let pruefungenAsArray = Array.isArray(pruefungen) ? pruefungen : [pruefungen];

            pruefungenAsArray = pruefungenAsArray.map(pruefung => {
                return {
                    id: pruefung.id,
                    titel: pruefung.titel,
                    geprueftVon: values.pruefungVon,
                    geprueftAm: moment(values.pruefdatum).format('DD.MM.YYYY'),
                    elementId: pruefung.elementId,
                    elementTitel: pruefung.element.titel,
                    kommentar: ''
                };
            });

            console.log(pruefungenAsArray);
            setAbgeschlossenePruefungen(pruefungenAsArray);
            next();
        } else {
            notification.error({
                message: 'Fehler!',
                description: 'Bitte wählen Sie mindestens eine Prüfung aus',
                placement: 'topRight',
                duration: 10
            });
        }
    };

    const onFinishFailed = (errorInfo) => {
        notification.error({
            message: 'Fehler!',
            description: 'Sie haben nicht alle Pflichtfelder ausgefüllt',
            placement: 'topRight',
            duration: 10
        });
        console.log('Failed:', errorInfo);
    };

    const onChange = nextTargetKeys => {
        setTargetKeys(nextTargetKeys);
    };

    const loadAnstehendePruefungen = () => {
        setLoading(true);
        const pruefungenListe = [];
        try {

            for (const building of objektDetails.buildings) {
                for (const part of building.parts) {
                    for (let element of part.elemente) {
                        if (Array.isArray(element.naechstePruefungen)) {
                            for (let pruefung of element.naechstePruefungen) {
                                if (!pruefung.preview) {
                                    pruefungenListe.push({
                                        key: pruefung.id,
                                        objektTitel: objektDetails.titel,
                                        element: {
                                            titel: element.titel,
                                            buildingpart: part
                                        },
                                        ...pruefung
                                    });
                                }
                            }
                        }
                    }
                }
            }
            setObjektPruefungen(pruefungenListe);

            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    useEffect(async () => {
        loadAnstehendePruefungen();
    }, [props.accountData]);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const dateFormat = 'DD.MM.YYYY';

    const firstStepColumns = [
        {
            title: 'Prüfung',
            dataIndex: 'titel',
            key: 'titel',
        }, {
            title: 'Beschreibung',
            dataIndex: 'beschreibung',
            key: 'beschreibung',
        }, {
            title: 'Element',
            dataIndex: ['element', 'titel'],
            key: 'element.titel',
        }, {
            title: 'Etage',
            dataIndex: ['element', 'buildingpart', 'floor'],
            key: 'element.buildingpart.floor',
            sorter: (a, b) => a.etage.localeCompare(b.etage)
        }, {
            title: 'Raum',
            dataIndex: ['element', 'buildingpart', 'name'],
            key: 'element.buildingpart.name',
            sorter: (a, b) => a.raum.localeCompare(b.raum)
        }, {
            title: 'Letzte Prüfung',
            dataIndex: 'letztePruefung',
            key: 'letztePruefung',
            render: (text, row, index) => {
                return moment(text).format('DD.MM.YYYY');
            }
        }, {
            title: 'Nächste Prüfung',
            dataIndex: 'naechstePruefung',
            key: 'naechstePruefung',
            sorter: (a, b) => a.naechstePruefung.localeCompare(b.naechstePruefung),
            render: (text, row, index) => {
                return moment(text).format('DD.MM.YYYY');
            }
        }, {
            title: 'Prüfung von',
            dataIndex: 'pruefungVon',
            key: 'pruefungVon',
            render: (text, row, index) => {
                return translatePruefer(text);
            }
        },
        {
            title: 'Art',
            key: 'art',
            dataIndex: 'art',
            filters: [
                {
                    text: 'tausch',
                    value: 'tausch',
                }, {
                    text: 'pruefung',
                    value: 'pruefung',
                },
            ],
            onFilter: (value, record) => record.art.indexOf(value) === 0,
        },
    ];

    const firstStep = () => {

        const selectRow = (record) => {
            const tempSelectedRowKeys = [...selectedRowKeys];
            if (tempSelectedRowKeys.indexOf(record.key) >= 0) {
                tempSelectedRowKeys.splice(tempSelectedRowKeys.indexOf(record.key), 1);
            } else {
                tempSelectedRowKeys.push(record.key);
            }
            setSelectedRowKeys(tempSelectedRowKeys);
        };
        const onSelectedRowKeysChange = (tempSelectedRowKeys) => {
            setSelectedRowKeys(tempSelectedRowKeys);
        }

        const rowSelection = {
            selectedRowKeys,
            onChange: onSelectedRowKeysChange,
        };


        return (
            <Form
                {...layout}
                name="basic"
                initialValues={{remember: false}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >

                <Form.Item
                    label="Prüfung von"
                    name="pruefungVon"
                    rules={[{required: true, message: 'Bitte befüllen'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Prüfung durchgeführt am"
                    name="pruefdatum"
                    rules={[{required: true, message: 'Bitte befüllen'}]}
                >
                    <DatePicker format={dateFormat} locale={de_DE} placeholder="Datum"/>
                </Form.Item>

                <Table
                    rowSelection={rowSelection}
                    dataSource={objektPruefungen}
                    columns={firstStepColumns}
                    loading={loading}
                    onRow={(record) => ({
                        onClick: () => {
                            selectRow(record);
                        },
                    })}
                />
                <br/>
                <Form.Item {...tailLayout}>
                    <Button htmlType='button' style={{marginLeft: 8, marginRight: 8, marginBottom: 12}}>
                        <Link to={`/${getActiveAccountId()}/pruefungen?objektId=${objektId}`}>Zurück</Link>
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Weiter
                    </Button>
                </Form.Item>
            </Form>);
    }

    const isEditing = (record) => record.id === editingKey;

    const editModal = (record) => {
        console.log(record);

        formTabellemodal.setFieldsValue({
            id: record.id,
            kommentar: record.kommentar,
            geprueftVon: record.geprueftVon,
            geprueftAm: moment(record.geprueftAm, dateFormat)
        });
        setEditingKey(record.id);
        setEditModalVisible(true);

    }

    const editModalOk = async (event) => {
        const record = abgeschlossenePruefungen.find((item) => editingKey === item.id);

        await save(record.id);
        setEditingKey('');
        setEditModalVisible(false);
    };

    const editModalCancel = (record) => {
        setEditingKey('');
        setEditModalVisible(false);
    };

    const upload = (record) => {
        setUploadingKey(record.id);
        setFileList(Array.isArray(record.attachments) ? record.attachments : []);
        setUploadFileModalVisible(true);
    };

    const uploadFileModalOk = (event) => {

        const record = abgeschlossenePruefungen.find((item) => uploadingKey === item.id);
        record.attachments = fileList;

        const newData = [...abgeschlossenePruefungen];
        const index = newData.findIndex((item) => uploadingKey === item.id);

        const item = newData[index];
        newData.splice(index, 1, {...item, ...record});
        setAbgeschlossenePruefungen(newData);

        setFileList([]);
        setUploadFileModalVisible(false);
    };

    const uploadFileModalCancel = (record) => {
        setUploadFileModalVisible(false);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (id) => {
        try {
            let row = await formTabellemodal.validateFields();
            const newData = [...abgeschlossenePruefungen];
            const index = newData.findIndex((item) => id === item.id);

            if (row && moment.isMoment(row.geprueftAm)) {
                row.geprueftAm = row.geprueftAm.format(dateFormat);
            }

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {...item, ...row});
                setAbgeschlossenePruefungen(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setAbgeschlossenePruefungen(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const baseColumns = [{
        title: 'Element',
        dataIndex: 'elementTitel',
        width: '15%',
    }, {
        title: 'Prüfung',
        dataIndex: 'titel',
        width: '15%',
    }, {
        title: 'Geprüft von',
        dataIndex: 'geprueftVon',
        width: '15%',
        editable: true,
    }, {
        title: 'Geprüft am',
        dataIndex: 'geprueftAm',
        width: '20%',
        editable: true,
        inputType: 'date',
    }, {
        title: 'Pruefkommentar',
        dataIndex: 'kommentar',
        editable: true,
        notRequired: true,
        width: '45%'
    }, {
        title: 'Anhänge',
        dataIndex: 'attachments',
        editable: false,
        width: '20%',
        render: (text, row, index) => {
            if (Array.isArray(text)) {
                return text.map(attachment => {
                    return <p>{attachment.name}</p>;
                });
            }
            return "";
        }
    }
    ];

    const columns = [...baseColumns,
        {
            title: 'Aktionen',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <a
                href="javascript:;"
                onClick={() => save(record.id)}
                style={{
                    marginRight: 8,
                }}
            >

            </a>
            <br/>
            <Popconfirm title="Wirklich verwerfen?" onConfirm={cancel} okText="Ja" cancelText="Nein">
              <a></a>
            </Popconfirm>
          </span>
                ) : (
                    <React.Fragment>
                        <Typography.Link onClick={() => upload(record)}>
                            Datei hochladen
                        </Typography.Link>
                        <br/>
                        <Typography.Link disabled={editingKey !== ''} onClick={() => editModal(record)}>
                            Bearbeiten
                        </Typography.Link>
                    </React.Fragment>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        return col;
    });

    const onFinishDatenerfassen = (values) => {
        console.log('Success:', values);
        next();
    };

    const onFinishFailedDatenerfassen = (errorInfo) => {
        notification.error({
            message: 'Fehler!',
            description: 'Sie haben nicht alle Pflichtfelder ausgefüllt',
            placement: 'topRight',
            duration: 10
        });
        console.log('Failed:', errorInfo);
    };

    const uploadProps = {
        name: 'file',
        multiple: true,
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList
    };

    const datenerfassenStep = () => {
        return (
            <React.Fragment>
                <Form form={formTabelle}
                      onFinish={onFinishDatenerfassen}
                      onFinishFailed={onFinishFailedDatenerfassen}
                >
                    <Table
                        bordered
                        dataSource={abgeschlossenePruefungen}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={{
                            onChange: cancel,
                        }}
                    />


                    <Form.Item {...tailLayout}>
                        <Button style={{margin: '0 8px'}} onClick={() => prev()}>
                            Zurück
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Weiter
                        </Button>
                    </Form.Item>

                    <Modal
                        title="Dateien hinzufügen"
                        visible={uploadFileModalVisible}
                        onOk={uploadFileModalOk}
                        onCancel={uploadFileModalCancel}
                        okText="Hinzufügen"
                        cancelText="Verwerfen"
                        destroyOnClose={true}
                    >
                        <Dragger {...uploadProps}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">Drücken Sie hier um eine Datei hochzuladen.</p>
                            <p className="ant-upload-hint">
                                Alternativ können Sie eine oder mehrere Dateien in dieses Feld ziehen um einen Upload zu
                                starten.
                            </p>
                        </Dragger>
                    </Modal>
                </Form>
                <Modal
                    title="Daten bearbeiten"
                    visible={editModalVisible}
                    onOk={editModalOk}
                    onCancel={editModalCancel}
                    okText="Speichern"
                    cancelText="Verwerfen"
                    destroyOnClose={true}
                >
                    <Form
                        form={formTabellemodal}
                        initialValues={{remember: false}}
                    >
                        <Form.Item
                            label="Prüfung von"
                            name="geprueftVon"
                            rules={[{required: true, message: 'Bitte befüllen'}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Prüfung durchgeführt am"
                            name="geprueftAm"
                            rules={[{required: true, message: 'Bitte befüllen'}]}
                        >
                            <DatePicker format={dateFormat} locale={de_DE} placeholder="Datum"/>
                        </Form.Item>

                        <Form.Item
                            name="kommentar"
                            label="Prüfkommentar"
                        >
                            <TextArea rows={4}/>
                        </Form.Item>
                    </Form>


                </Modal>
            </React.Fragment>
        );
    }

    const onFinishLastStep = async (values) => {
        try {

            for (let abgeschlossenePruefung of abgeschlossenePruefungen) {
                abgeschlossenePruefung.geprueftAm = moment(abgeschlossenePruefung.geprueftAm, 'DD.MM.YYYY').toDate().toISOString();

                const formData = new FormData();

                if (abgeschlossenePruefung.attachments && Array.isArray(abgeschlossenePruefung.attachments)) {
                    abgeschlossenePruefung.attachments.forEach(file => {
                        formData.append('files', file);
                    });
                }

                formData.append('id', abgeschlossenePruefung.id);
                formData.append('elementId', abgeschlossenePruefung.elementId);
                formData.append('elementTitel', abgeschlossenePruefung.elementTitel);
                formData.append('geprueftAm', abgeschlossenePruefung.geprueftAm);
                formData.append('geprueftVon', abgeschlossenePruefung.geprueftVon);
                formData.append('kommentar', abgeschlossenePruefung.kommentar);
                formData.append('titel', abgeschlossenePruefung.titel);

                const result = await ApiWrapper.fileUpload('objekt-verwaltung-api', `/${props.activeAccountId}/objekte/${objektId}/pruefung/erledigen`, formData);
                console.log(result)

            }
            dispatch(fetchDataAsync({activeAccountId: getActiveAccountId()}));
            navigate(`/objekt/${objektId}`);
        } catch (e) {
            console.error(e)
        }
    };

    const onFinishFailedLastStep = (errorInfo) => {
        notification.error({
            message: 'Fehler!',
            description: 'Sie haben nicht alle Pflichtfelder ausgefüllt',
            placement: 'topRight',
            duration: 10
        });
        console.log('Failed:', errorInfo);
    };

    const onCheckboxChange = async (e) => {
        await setChecked(e.target.checked);
        form.validateFields(['checkbox']);
    };

    const checkboxValidation = (RuleObject, value) => {
        return new Promise(function (fulfilled, rejected) {
            if (!checked) {
                notification.error({
                    message: 'Fehler!',
                    description: 'Bitte bestätigen Sie, dass...',
                    placement: 'topRight',
                    duration: 10
                });
                return rejected(new Error("Bitte bestätigen Sie, dass... "))
            } else {
                fulfilled();
            }
        })
    };

    const lastStep = () => {
        return (
            <Form
                {...layout}
                form={form}
                name="basic"
                initialValues={{remember: false}}
                onFinish={onFinishLastStep}
                onFinishFailed={onFinishFailedLastStep}
            >

                <h1>Übersicht der geprüften Elemente</h1>
                <br/>
                <Table
                    bordered
                    dataSource={abgeschlossenePruefungen}
                    columns={baseColumns}
                    rowClassName="editable-row"
                    pagination={{
                        onChange: cancel,
                    }}
                />

                <Form.Item
                    name="checkbox"
                    rules={[{validator: checkboxValidation}]}
                >
                    <Checkbox checked={checked} onChange={onCheckboxChange}>
                        Hiermit bestätige ich.... - inkl. custom Validierung.
                    </Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button style={{margin: '0 8px'}} onClick={() => prev()}>
                        Zurück
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Prüfungen bestätigen
                    </Button>
                </Form.Item>
            </Form>);
    }


    const steps = [
        {
            title: 'Prüfungen auswählen',
            content: firstStep(),
        },
        {
            title: 'Daten erfassen',
            content: datenerfassenStep(),
        },
        {
            title: 'Bestätigen',
            content: lastStep(),
        },
    ];


    useEffect(() => {
        loadAnstehendePruefungen();
    }, []);


    return (
        <SiteLayout pageTitle="Prüfungen für Element">

            <Descriptions title={objektDetails.name} layout="vertical">
            </Descriptions>

            <Steps current={current}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title}/>
                ))}
            </Steps>

            <Divider/>

            <div className="steps-content">{steps[current].content}</div>

        </SiteLayout>
    );
};

export default ObjectPruefungenDokumentierenContainer;
