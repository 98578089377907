import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Select, Form, PageHeader, Input, Modal, Button, notification} from 'antd';
import {MinusOutlined, PlusOutlined, InfoCircleOutlined} from '@ant-design/icons';

import ApiWrapper from '../Utils/ApiWrapper';
import SiteLayout from '../Components/Styled/SiteLayout';

import moment from 'moment';
import {getActiveAccountId, translatePruefer} from "../Utils/helpers";
import {fetchDataAsync, selectObject, selectObservable, selectPastAudit} from "../app/dataSlice";
import {useAppDispatch} from "../app/hooks";
import {useSelector} from "react-redux";


const auditDetailssDetailContainer = (props) => {
    const [form] = Form.useForm();
    const {TextArea} = Input;

    const params = useParams();

    const objektId = params.objektId;
    const buildingId = params.buildingId;
    const buildingpartId = params.buildingpartId;
    const elementId = params.elementId;
    const pruefungId = params.pruefungsId;

    let navigate = useNavigate();
    const dispatch = useAppDispatch();

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };

    const objektDetails = useSelector(selectObject(objektId));
    const observableDetails = useSelector(selectObservable(elementId));
    const auditDetails = useSelector(selectPastAudit(pruefungId));

    const onFinish = (values) => {
        let valuesEdited = {};
        if (values.commentAdded != '' || values.commentAdded != 'undefined') {
            valuesEdited.kommentar = `${auditDetails.comment} ${values.commentAdded}`
        }

        valuesEdited.id = pruefungId;
        valuesEdited.elementId = auditDetails.elementId;
        console.log(valuesEdited);

        ApiWrapper.put('objekt-verwaltung-api', `/${props.activeAccountId}/objekte/${objektId}/${elementId}/auditDetails/${auditDetailsId}`, valuesEdited).then(async (result) => {
            notification.success({
                message: 'Prüfung ergänzt!',
                description: 'Die Prüfung wurde erfolgreich ergänzt!',
                placement: 'topRight',
                duration: 5
            });
            dispatch(fetchDataAsync({activeAccountId: getActiveAccountId()}));
        }).catch(err => {
            notification.error({
                message: 'Fehler!',
                description: 'Die Prüfung konnte nicht gespeichert werden. ',
                placement: 'topRight',
                duration: 5
            });
            console.log(err);
        });
    };


    const onFinishFailed = (errorInfo) => {
        notification.error({
            message: 'Fehler!',
            description: 'Sie haben nicht alle Pflichtfelder ausgefüllt.',
            placement: 'topRight',
            duration: 10
        });
        console.log('Failed:', errorInfo);
    };

    const renderKommentar = () => {
        if (auditDetails.comment === "" || auditDetails.comment === "undefined" || !auditDetails.comment) {
            const commentJSX = <Form.Item label="Prüfkommentar anlegen" name="commentAdded">
                <TextArea rows={4}/>
            </Form.Item>
            return commentJSX;
        } else {
            var commentJSX = <div>
                <Form.Item label="Bisheriger Prüfkommentar" name="comment">
                    <h4>{`${auditDetails.comment}`}</h4>
                </Form.Item>
                <Form.Item label="Prüfkommentar ergänzen" name="commentAdded">
                    <TextArea rows={4}/>
                </Form.Item>
            </div>
            return commentJSX;
        }
    }

    const renderBeschreibung = () => {
        if (auditDetails.beschreibung === "" || auditDetails.beschreibung === "undefined" || !auditDetails.beschreibung) {
            return
        } else {
            return (
                <Form.Item label="Beschreibung" name="beschreibung">
                    <h4>{`${auditDetails.beschreibung}`}</h4>
                </Form.Item>
            )
        }
    }

    const renderArt = () => {
        const text = auditDetails.art === "auditDetails" ? "Überprüfung" : "Austausch";
        return (
            <Form.Item label="Art der Prüfung" name="comment">
                <h4>{text}</h4>
            </Form.Item>
        );
    }

    const renderNotwendigerPruefer = () => {
        if (auditDetails.pruefungVon === "" || auditDetails.pruefungVon === "undefined" || !auditDetails.auditDetailsVon) {
            return;
        } else {
            return <Form.Item label="Notwendiger Prüfer" name="">
                <h4>{`${translatePruefer(auditDetails.pruefungVon)}`}</h4>
            </Form.Item>;
        }
    };

    const downloadFile = async (attachmentId) => {
        await ApiWrapper.download('objekt-verwaltung-api', `/${getActiveAccountId()}/objekte/${objektId}/download/${attachmentId}`)
    }

    const renderAttachments = () => {

        if (!auditDetails.attachments || !Array.isArray(auditDetails.attachments)) {
            return (<p>Keine Anhänge vorhanden</p>)
        }

        const attachments = [];

        for (const att of auditDetails.attachments) {
            attachments.push(<Link to={""} onClick={() => downloadFile(att.id)}>-> {att.name}</Link>);
        }

        return attachments;

    }

    return (
        <SiteLayout pageTitle="Element Bearbeiten" breadcumItems={[
            <Link to={`/objekte`}>Account</Link>,
            <Link to={`/objekte`}>Objektübersicht</Link>,
            <Link to={`/objekt/${objektId}`}>Objekt</Link>,
            <Link to={`/objekt/${objektId}/building/${buildingId}/part/${buildingpartId}/element/${elementId}/details`}>Element</Link>,
            `Prüfungsdetails`
        ]}>

            <PageHeader
                ghost={false}
                title={`${objektDetails.titel} - Element: ${observableDetails.titel}`}
            ></PageHeader>

            <br/>

            <div style={{width: "80%"}}>
                <Form form={form}
                      {...layout}
                      name="basic"
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      scrollToFirstError={true}
                      autoComplete="off"
                      labelAlign="left"
                >
                    <div style={{textAlign: 'left'}}>
                        <Form.Item
                            label="Titel"
                            name="titel"
                        >
                            <h3>{`${auditDetails.titel}`}</h3>
                        </Form.Item>

                        {renderBeschreibung()}

                        <Form.Item
                            label="Erledigt am"
                            name="resolvedAt"
                        >
                            <h4>{`${moment(auditDetails.resolvedAt).format('DD.MM.YYYY')}`}</h4>
                        </Form.Item>

                        <Form.Item
                            label="Zuletzt bearbeitet"
                            name="updatedAt"
                        >
                            <h4>{`${moment(auditDetails.updatedAt).format('DD.MM.YYYY')}`}</h4>
                        </Form.Item>

                        {renderArt()}

                        {renderNotwendigerPruefer()}

                        <Form.Item
                            label="Geprüft durch"
                            name="resolvedFrom"
                        >
                            <h4>{`${auditDetails.resolvedFrom}`}</h4>
                        </Form.Item>

                        <Form.Item
                            label="Anhänge">
                            {renderAttachments()}
                        </Form.Item>

                        {renderKommentar()}

                        <Button htmlType='button' style={{marginLeft: 8, marginRight: 8, marginBottom: 12}}>
                            <Link to={`/objekt/${objektId}/building/${buildingId}/part/${buildingpartId}/element/${elementId}/details`}>Abbrechen</Link>
                        </Button>
                        <Button type="primary" htmlType="submit" style={{marginLeft: 8, marginRight: 8}}>
                            Speichern
                        </Button>
                    </div>
                </Form>
            </div>

        </SiteLayout>
    );
};
export default auditDetailssDetailContainer;
