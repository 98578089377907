import * as React from 'react';
import {Link, useParams} from 'react-router-dom';

import {
    Steps,
    Button,
    Collapse,
    Form,
    Table,
    Space,
    Modal,
    notification, Tag
} from 'antd';

const {Step} = Steps;
const {Panel} = Collapse;

import SiteLayout from '../Components/Styled/SiteLayout';
import {useEffect, useState} from "react";
import {PlusCircleOutlined} from "@ant-design/icons";
import ApiWrapper from "../Utils/ApiWrapper";

import uuid from 'react-uuid'
import Element from "../Components/Element";
import {getActiveAccountId} from "../Utils/helpers";
import Raum from "../Components/Raum";
import Gebaeude from "../Components/Gebaeude";
import {fetchDataAsync, selectObject} from "../app/dataSlice";
import {useAppDispatch} from "../app/hooks";
import {useSelector} from "react-redux";
import {selectObservableTypes} from "../app/observabletypesSlice";

const ElementCreateGuidedContainer = (props) => {

    const params = useParams();
    const objektId = params.objektId;

    const objektDetails = useSelector(selectObject(objektId));

    const [gebaudeImObjekt, setGebaudeImObjekt] = useState(JSON.parse(JSON.stringify(objektDetails.buildings)));

    const [raumKategorien, setraumKategorien] = useState([{
        id: '1111', titel: 'Sonstige', cat: 'Allgemein'
    }]);

    // Gebäude
    const [activGebaeude, setActivGebaeude] = useState({});
    const [activGebaeudeteil, setActivGebaeudeteil] = useState({});

    const [raumKatIdForNextRaum, setRaumKatIdForNextRaum] = useState('');

    const [raumDetailsToEdit, setRaumDetailsToEdit] = useState({});

    const [isCreateGebaeudeModalVisible, setIsCreateGebaeudeModalVisible] = useState(false);
    const [isCreateRoomModalVisible, setIsCreateRoomModalVisible] = useState(false);
    const [isCreateElementModalVisible, setIsCreateElementModalVisible] = useState(false);

    const [isDeleteGebaeudeModalVisible, setIsDeleteGebaeudeModalVisible] = useState(false);
    const [isDeleteRaumModalVisible, setIsDeleteRaumModalVisible] = useState(false);
    const [isDeleteElementModalVisible, setIsDeleteElementModalVisible] = useState(false);

    const [gebaeudeIdToDelete, setGebaeudeIdToDelete] = useState('');
    const [raumIdToDelete, setRaumIdToDelete] = useState('');
    const [elementIdToDelete, setElementIdToDelete] = useState('');

    const [elementToCreateOrEdit, setElementToCreateOrEdit] = useState({});

    const [current, setCurrent] = React.useState(0);

    const dispatch = useAppDispatch();

    const observableTypes = useSelector(selectObservableTypes);

    const deleteBuilding = async (gebaeudeId) => {
      // AKTUELL WERDE AUCH GELÖSCHTE GEBÄUDE IN DER LISTE ANGEZEIGT
        ApiWrapper.del('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building/${gebaeudeId}`, {}).then((result) => {
            console.log(result);
            dispatch(fetchDataAsync({activeAccountId: getActiveAccountId()}));
        }).catch(err => {
            console.log(err);
            notification['error']({
                message: 'Das Gebäude konnte nicht gelöscht werden',
                description: err.message,
                //ÜBERSETZEN
                placement: 'topRight',
                duration: 5
            });
        });
    };

    const deleteBuildingPart = async (gebaeudeteilId) => {

        ApiWrapper.del('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building/${activGebaeude.id}/part/${gebaeudeteilId}`, {}).then((result) => {
            console.log(result);
            dispatch(fetchDataAsync({activeAccountId: getActiveAccountId()}));
        }).catch(err => {
            console.log(err);
            notification['error']({
                message: 'Der Gebäudeteil konnte nicht gelöscht werden',
                description: err.message,
                //ÜBERSETZEN
                placement: 'topRight',
                duration: 5
            });
        });
    };

    const deleteElement = async (gebaeudeteilId) => {

        ApiWrapper.del('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building/${activGebaeude.id}/part/${activGebaeudeteil.id}/observable/${elementIdToDelete}`, {}).then((result) => {
            console.log(result);
            dispatch(fetchDataAsync({activeAccountId: getActiveAccountId()}));
        }).catch(err => {
            console.log(err);
            notification['error']({
                message: 'Das Element konnte nicht gelöscht werden',
                description: err.message,
                //ÜBERSETZEN
                placement: 'topRight',
                duration: 5
            });
        });
    };

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const step_uebersicht = () => {

        const [form_gebaude] = Form.useForm();

        const genExtra = () => (
          <Button
            onClick={event => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
                form_gebaude.resetFields();
                setIsCreateGebaeudeModalVisible(true);
            }}>
              Hinzufügen
            </Button>
      );

        const gebaeudeBearbeiten = (gebaeudeId) => {
            form_gebaude.resetFields();
            setActivGebaeude(gebaudeImObjekt.find(gebaeude => gebaeude.id === gebaeudeId));
            setIsCreateGebaeudeModalVisible(true);
        }

        const gebaeudeteileBearbeiten = (gebaeudeId) => {
            setActivGebaeude(gebaudeImObjekt.find(gebaeude => gebaeude.id === gebaeudeId));
            next();
        }

        const onOkGebaeudeDeleteModal = () => {
          setIsDeleteGebaeudeModalVisible(false);
          gebaeudeLoeschen(gebaeudeIdToDelete);
        }

        const onCloseGebaeudeModal = () => {
          setIsDeleteGebaeudeModalVisible(false);
        }

        const gebaeudeLoeschen = (gebaeudeId) => {
          console.log(`Gebaeude soll gelöscht werden: ${gebaeudeId}`);
          deleteBuilding(gebaeudeId);
        }

        const onFinishFirstStep = () =>{
            dispatch(fetchDataAsync({activeAccountId: getActiveAccountId()}));
          notification.success({
            message: 'Einrichtung abgeschlossen!',
            description: 'Sie haben die Einrichtung des Objekt erfolgreich abgeschlossen.',
            placement: 'topRight',
            duration: 5
          });
        }


        const onOk = async () => {

            try {
                const values = await form_gebaude.validateFields();
                console.log(values);
                if (!values.id) {
                    ApiWrapper.post('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building`, values).then(async (result) => {
                        const body = JSON.parse(result.body);
                        body.parts = [];
                        setGebaudeImObjekt([...gebaudeImObjekt, body]);
                        setIsCreateGebaeudeModalVisible(false);
                    }).catch(err => {
                        console.log(err);
                        setIsCreateGebaeudeModalVisible(false);
                    });
                } else {
                    ApiWrapper.put('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building/${values.id}`, values).then(async (result) => {
                        const body = JSON.parse(result.body);
                        const oldGebaeude = gebaudeImObjekt.find(gb => gb.id === body.id);
                        body.parts = oldGebaeude.parts;
                        setGebaudeImObjekt(gebaudeImObjekt.map(gb => gb.id === body.id ? body : gb));
                        setIsCreateGebaeudeModalVisible(false);
                    }).catch(err => {
                        console.log(err);
                        setIsCreateGebaeudeModalVisible(false);
                    });

                }
            } catch (err) {
                if (err.errorFields) {
                    for (const errorField of err.errorFields) {
                        notification.error({
                            message: `Fehler in Feld ${errorField.name.join('')}`,
                            description: errorField.errors.join(', '),
                            placement: 'topRight',
                            duration: 5
                        });
                    }
                } else {
                    notification.error({
                        message: 'Fehler bei der Eingabe!',
                        description: 'Bei der Validierung der Eingabe kam es zu einem Fehler!',
                        placement: 'topRight',
                        duration: 5
                    });
                }
                console.log(err);
            }
        }

        const onClose = () => {
            setIsCreateGebaeudeModalVisible(false);
        }

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Beschreibung',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Aktion',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <a href="#"
                           onClick={() => gebaeudeteileBearbeiten(record.id)}>Teile</a>
                        <a href="#"
                           onClick={() => gebaeudeBearbeiten(record.id)}>Bearbeiten</a>
                        <a href="#"
                           onClick={() => {setIsDeleteGebaeudeModalVisible(true); setGebaeudeIdToDelete(`${record.id}`)}}>Löschen</a>
                    </Space>
                ),
            },
        ];

        return (<React.Fragment>
                <h2>Gebäude</h2>
                <Collapse
                    defaultActiveKey={['1']}
                >
                    <Panel header="Gebäude" key="1" extra={genExtra()}>
                        <Table columns={columns} dataSource={gebaudeImObjekt}/>
                    </Panel>
                </Collapse>
                <Modal
                    title="Gebäude anlegen/bearbeiten"
                    visible={isCreateGebaeudeModalVisible}
                    onOk={onOk}
                    onCancel={onClose}
                    destroyOnClose={true}
                    cancelText="Zurück"
                >
                    <Gebaeude
                        form={form_gebaude}
                        raumDetails={activGebaeude}
                    />
                </Modal>

                <Modal
                    title="Gebäude löschen"
                    visible={isDeleteGebaeudeModalVisible}
                    onOk={onOkGebaeudeDeleteModal}
                    onCancel={onCloseGebaeudeModal}
                    cancelText="Zurück"
                    destroyOnClose={true}
                >
                    <div style={{alignItems:'center', textAlign: 'center'}}>Soll das Gebäude wirklich gelöscht werden?</div>
                </Modal>
                  <br/>

                <Button type="primary" style={{marginLeft: 8, marginRight: 8}}>
                    <Link  to={`/objekt/${objektId}`}>Abbrechen</Link>
                </Button>

                <Button type="primary" style={{marginLeft: 8, marginRight: 8}}>
                    <Link  to={`/objekt/${objektId}`} onClick={() => onFinishFirstStep()}>Fertig und Anlegen</Link>
                </Button>
            </React.Fragment>
        )
    }

    const step_gebaeude = () => {

        const [form_raume] = Form.useForm();

        const genExtra = (raumKategorieId) => (
          <Button
            onClick={event => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
                form_raume.resetFields();
                setRaumKatIdForNextRaum(raumKategorieId);
                setIsCreateRoomModalVisible(true);
            }}>
                Hinzufügen
            </Button>
      );

        const raumBearbeiten = (gebaeudeteilId) => {
            form_raume.resetFields();
            setRaumDetailsToEdit(activGebaeude.parts.find(gbt => gbt.id === gebaeudeteilId) ?? {});
            setIsCreateRoomModalVisible(true);
        }

        const raumElementeBearbeiten = (gebaeudeteilId) => {
            setActivGebaeudeteil(activGebaeude.parts.find(gbt => gbt.id === gebaeudeteilId));
            next();
        }

        const onOkRaumDeleteModal = () => {
          setIsDeleteRaumModalVisible(false);
          raumLoeschen(raumIdToDelete);
        }

        const onCloseRaumModal = () => {
          setIsDeleteRaumModalVisible(false);
        }

        const raumLoeschen = (gebaeudeteilId) => {
            console.log(`Gebäudeteil soll gelöscht werden: ${gebaeudeteilId}`);
            deleteBuildingPart(gebaeudeteilId);
        }

        const onOk = async () => {

            try {
                const values = await form_raume.validateFields();
                console.log(values);
                if (!values.id) {
                    values.categoryId = raumKatIdForNextRaum;
                    ApiWrapper.post('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building/${activGebaeude.id}/part`, values).then(async (result) => {
                        const body = JSON.parse(result.body);

                        body.elemente = [];
                        activGebaeude.parts.push(body);
                        setActivGebaeude(activGebaeude);
                        //setActivGebaeudeteil(values);
                        //next();
                        setIsCreateRoomModalVisible(false);
                    }).catch(err => {
                        console.log(err);
                        setIsCreateRoomModalVisible(false);
                    });
                } else {
                    ApiWrapper.put('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building/${activGebaeude.id}/part/${values.id}`, values).then(async (result) => {
                        const body = JSON.parse(result.body);
                        const oldGebaeudeteil = activGebaeude.parts.find(gbt => gbt.id === body.id);
                        //body.kategorieId = oldGebaeudeteil.kategorieId;
                        body.elemente = oldGebaeudeteil.elemente;
                        activGebaeude.parts = activGebaeude.parts.map(gbt => gbt.id === body.id ? body : gbt);
                        setActivGebaeude(activGebaeude);
                        setIsCreateRoomModalVisible(false);
                    }).catch(err => {
                        console.log(err);
                        setIsCreateRoomModalVisible(false);
                    });
                }
            } catch (err) {
                if (err.errorFields) {
                    for (const errorField of err.errorFields) {
                        notification.error({
                            message: `Fehler in Feld ${errorField.name.join('')}`,
                            description: errorField.errors.join(', '),
                            placement: 'topRight',
                            duration: 5
                        });
                    }
                } else {
                    notification.error({
                        message: 'Fehler bei der Eingabe!',
                        description: 'Bei der Validierung der Eingabe kam es zu einem Fehler!',
                        placement: 'topRight',
                        duration: 5
                    });
                }
                console.log(err);
            }
        }

        const onClose = () => {
            setIsCreateRoomModalVisible(false);
        }

        const renderraumKategorie = () => {

            const elemente = [];

            const columns = [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: 'Beschreibung',
                    dataIndex: 'description',
                    key: 'description',
                },
                {
                    title: 'Etage',
                    key: 'floor',
                    dataIndex: 'floor',
                },
                {
                    title: 'Raum Nr.',
                    key: 'number',
                    dataIndex: 'number',
                },
                {
                    title: 'Aktion',
                    key: 'action',
                    render: (text, record) => (
                        <Space size="middle">
                            <a href="#"
                               onClick={() => raumElementeBearbeiten(record.id)}>Elemente</a>
                            <a href="#"
                               onClick={() => raumBearbeiten(record.id)}>Bearbeiten</a>
                            <a href= "#"
                               onClick={() => {setIsDeleteRaumModalVisible(true), setRaumIdToDelete(`${record.id}`)}}>Löschen</a>
                        </Space>
                    ),
                },
            ];

            for (const raumKategorie of raumKategorien) {
                let ds = [];
                if (activGebaeude && Array.isArray(activGebaeude.parts)) {
                    ds = activGebaeude.parts.filter(gbt => gbt.categoryId === raumKategorie.id)
                }

                elemente.push(
                    <Panel header={raumKategorie.titel} key="1" extra={genExtra(raumKategorie.id)}>
                        <Table columns={columns} dataSource={ds}/>
                    </Panel>
                );
            }
            return elemente;
        }

        return (<React.Fragment>
                <h2>Gebäudeteile in {activGebaeude.name}</h2>
                <Collapse
                    defaultActiveKey={['1']}
                >
                    {renderraumKategorie()}
                </Collapse>
                <Modal
                    title="Raum anlegen/bearbeiten"
                    visible={isCreateRoomModalVisible}
                    onOk={onOk}
                    onCancel={onClose}
                    destroyOnClose={true}
                >
                    <Raum
                        form={form_raume}
                        raumDetails={raumDetailsToEdit}
                    />
                </Modal>
                <Modal
                    title="Raum löschen"
                    visible={isDeleteRaumModalVisible}
                    onOk={onOkRaumDeleteModal}
                    onCancel={onCloseRaumModal}
                    destroyOnClose={true}
                >
                    <div style={{alignItems:'center', textAlign: 'center'}}> Soll der Raum wirklich gelöscht werden?</div>
                </Modal>
                  <br/>
                <Button type="primary" style={{marginLeft: 8, marginRight: 8}} onClick={() => prev()}>
                    Fertig
                </Button>
            </React.Fragment>
        )
    }

    const step_raum = () => {

        const [form_elemente] = Form.useForm();

        const genExtra = (elementKategorie) => (
          <Button
            onClick={event => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
                form_elemente.resetFields();
                const element = {
                    categoryId: elementKategorie
                };
                setElementToCreateOrEdit(element)
                setIsCreateElementModalVisible(true);
            }}>
                Hinzufügen
            </Button>
      );

        const onOk = async () => {

            try {
                const values = await form_elemente.validateFields();
                console.log(values);
                if (!values.id) {
                    ApiWrapper.post('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building/${activGebaeude.id}/part/${activGebaeudeteil.id}/observable`, values).then(async (result) => {
                        activGebaeudeteil.elemente.push(result);

                        console.log(activGebaeudeteil.elemente);
                        setActivGebaeudeteil(activGebaeudeteil);
                        setIsCreateElementModalVisible(false);
                    }).catch(err => {
                        console.log(err);
                        setIsCreateElementModalVisible(false);
                    });
                } else {
                    ApiWrapper.put('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building/${activGebaeude.id}/part/${activGebaeudeteil.id}/observable/${values.id}`, values).then(async (result) => {
                        activGebaeudeteil.elemente = activGebaeudeteil.elemente.map(ele => ele.id === result.id ? result : ele);

                        console.log(activGebaeudeteil.elemente);
                        setActivGebaeudeteil(activGebaeudeteil);
                        setIsCreateElementModalVisible(false);
                    }).catch(err => {
                        console.log(err);
                        setIsCreateElementModalVisible(false);
                    });
                }
            } catch (err) {
                if (err.errorFields) {
                    for (const errorField of err.errorFields) {
                        notification.error({
                            message: `Fehler in Feld ${errorField.name.join('')}`,
                            description: errorField.errors.join(', '),
                            placement: 'topRight',
                            duration: 5
                        });
                    }
                } else {
                    notification.error({
                        message: 'Fehler bei der Eingabe!',
                        description: 'Bei der Validierung der Eingabe kam es zu einem Fehler!',
                        placement: 'topRight',
                        duration: 5
                    });
                }
                console.log(err);
            }
        }


        const onCloseElementModals = () => {
            setIsCreateElementModalVisible(false);
            setIsDeleteElementModalVisible(false);
        };

        const elementBearbeiten = (element) => {
            form_elemente.resetFields();
            setElementToCreateOrEdit(element);
            setIsCreateElementModalVisible(true);
        }

        const onOkElementDeleteModal = () => {
          setIsDeleteElementModalVisible(false);
          elementLoeschen(elementIdToDelete);
        }

        const elementLoeschen = (elementIdToDelete) => {
            console.log(`Element soll gelöscht werden: `,elementIdToDelete);
            elementIdToDelete = elementIdToDelete.toString();
            deleteElement(elementIdToDelete);
        }

        const renderRaumElemente = () => {

            const elemente = [];

            const columns = [
                {
                    title: 'Name',
                    dataIndex: 'titel',
                    key: 'titel',
                },
                {
                    title: 'Beschreibung',
                    dataIndex: 'beschreibung',
                    key: 'beschreibung',
                },
                {
                    title: 'Kategorie',
                    dataIndex: 'categoryId',
                    key: 'categoryId',
                    render: categoryId => {
                        const category = observableTypes.find(observableType => observableType.id === categoryId);
                        return (
                            <Tag color={'green'}>
                                {category.titel.toUpperCase()}
                            </Tag>
                        );
                    },
                },
                {
                    title: 'Aktion',
                    key: 'action',
                    render: (text, record) => (
                        <Space size="middle">
                            <a href="#"
                               onClick={() => elementBearbeiten(record)}>Bearbeiten</a>
                            <a href="#"
                               onClick={() => {setIsDeleteElementModalVisible(true); setElementIdToDelete(`${record.id}`); console.log(`${record.id}`)}}>Löschen</a>
                        </Space>
                    ),
                },
            ];

            for (const [i, elementKategorie] of observableTypes.entries()) {

                let ds = [];
                if (activGebaeudeteil && Array.isArray(activGebaeudeteil.elemente)) {
                    ds = activGebaeudeteil.elemente.filter(element => element.categoryId === elementKategorie.id)
                }

                elemente.push(
                    <Panel header={elementKategorie.titel} key={i} extra={genExtra(elementKategorie.id)}>
                        <Table columns={columns} dataSource={ds}/>
                    </Panel>
                );
            }

            return elemente;
        }



        return (<React.Fragment>
                <h2>Elemente im Gebäudeteil {activGebaeudeteil.name}</h2>
                <Collapse
                >
                    {renderRaumElemente()}
                </Collapse>
                  <br/>
                <Button type="primary" style={{marginLeft: 8, marginRight: 8}} onClick={() => prev()}>
                    Fertig
                </Button>
                <Modal
                    title="Element anlegen/bearbeiten"
                    visible={isCreateElementModalVisible}
                    onOk={onOk}
                    onCancel={onCloseElementModals}
                    width={1000}
                    destroyOnClose={true}
                >
                    <Element form={form_elemente} objektId={objektId} element={elementToCreateOrEdit}/>
                </Modal>
                <Modal
                    title="Element löschen"
                    visible={isDeleteElementModalVisible}
                    onOk={onOkElementDeleteModal}
                    onCancel={onCloseElementModals}
                    destroyOnClose={true}
                >
                    <div style={{alignItems:'center', textAlign: 'center'}}>Soll das Element <b> &nbsp;{elementToCreateOrEdit.titel} &nbsp;</b> wirklich gelöscht werden?</div>

                </Modal>
            </React.Fragment>

        )
    }


    const steps = [{
        title: 'Gebäude', content: step_uebersicht(),
    }, {
        title: 'Gebäudeteile', content: step_gebaeude(),
    }, {
        title: 'Elemente', content: step_raum(),
    }];

    return (

        <SiteLayout pageTitle="Element Anlegen" breadcumItems={[<Link to={`/objekte`}>Account</Link>,
            <Link to={`/objekt/${objektId}`}>Objekt</Link>,]}>
            <Steps progressDot current={current}>
                {steps.map(item => (<Step key={item.title} title={item.title}/>))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
        </SiteLayout>);
};

export default ElementCreateGuidedContainer;
