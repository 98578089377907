import {Button, Space, Table, Tag} from "antd";
import React, {useEffect, useState} from "react";
import {ClockCircleOutlined, ExportOutlined, RetweetOutlined, SearchOutlined,InfoCircleOutlined} from "@ant-design/icons";
import {Link} from 'react-router-dom';

import moment from "moment";


import {translatePruefer} from "../../Utils/helpers";



const TablePruefungen = ({accountPruefungen, loading, objektFilter, elementFilter, activ = true}) => {

    const baseColumns = [
        {
            title: 'Art',
            key: 'art',
            dataIndex: 'art',
            width: '3%',
            filters: [
                {
                    text: 'tausch',
                    value: 'tausch',
                }, {
                    text: 'pruefung',
                    value: 'pruefung',
                },
            ],
            onFilter: (value, record) => record.art.indexOf(value) === 0,
            render: (text, row, index) => {
                if (text === 'austausch' || text === 'tausch') return <RetweetOutlined />;
                if (text === 'pruefung') return <SearchOutlined />;
                return text;
            }
        },{
            title: 'Objekt',
            dataIndex: 'objektTitel',
            key: 'objektTitel',
            filters: objektFilter,
            onFilter: (value, record) => record.objektId.indexOf(value) === 0,
        },{
            title: 'Element',
            dataIndex: ['element', 'titel'],
            key: 'element.titel',
            filters: elementFilter,
            onFilter: (value, record) => record.objektId.indexOf(value) === 0,
        },{
            title: 'Prüfung',
            dataIndex: 'titel',
            key: 'titel',
        }, {
            title: 'Beschreibung',
            dataIndex: 'beschreibung',
            key: 'beschreibung',
        },  {
            title: 'Etage',
            dataIndex: ['element', 'buildingpart', 'floor'],
            key: 'element.buildingpart.floor',
            width:'7%',
            sorter: (a, b) => a.etage.localeCompare(b.etage)
        }, {
            title: 'Raum',
            dataIndex: ['element', 'buildingpart', 'name'],
            key: 'element.buildingpart.name',
            width:'7%',
            sorter: (a, b) => a.raum.localeCompare(b.raum)

        },
    ];

    const columnsActivePruefungen = [
        {
            title: 'Letzte Prüfung',
            dataIndex: 'letztePruefung',
            key: 'letztePruefung',
            sorter: (a, b) => a.letztePruefung.localeCompare(b.letztePruefung),
            render: (text, row, index) => {
                if (!text) return <ClockCircleOutlined/>;
                return moment(text).format('DD.MM.YYYY');
            }
        }, {
            title: 'Nächste Prüfung',
            dataIndex: 'naechstePruefung',
            key: 'naechstePruefung',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => {
                return a.naechstePruefung.localeCompare(b.naechstePruefung)
            },
            render: (text, row, index) => {
                return moment(text).format('DD.MM.YYYY');
            }
        }, {
            title: 'Prüfung von',
            dataIndex: 'pruefungVon',
            key: 'pruefungVon',
            render: (text, row, index) => {
                return translatePruefer(text);
            }
        },
    ];

    const columnsVergangenePruefungen = [
        {
            title: 'Geprüft am',
            dataIndex: 'resolvedAt',
            key: 'resolvedAt',
            defaultSortOrder: 'descend',
            width:'7%',
            sorter: (a, b) => a.resolvedAt.localeCompare(b.resolvedAt),
            render: (text, row, index) => {
                if (!text) return <ClockCircleOutlined/>;
                return moment(text).format('DD.MM.YYYY');
            }
        },{
            title: 'Frist',
            dataIndex: 'naechstePruefung',
            key: 'naechstePruefung',
            width:'6%',
            sorter: (a, b) => {
                return a.naechstePruefung.localeCompare(b.naechstePruefung)
            },
            render: (text, row, index) => {
                return moment(text).format('DD.MM.YYYY');
            }
        },{
            title: 'Notwendiger Prüfer',
            dataIndex: 'pruefungVon',
            key: 'pruefungVon',
        },{
            title: 'Prüfer',
            dataIndex: 'resolvedFrom',
            key: 'resolvedFrom',
        },{
            title: 'Status',
            render: (text, row, index) => {
                console.log(row)
                const diff = moment(row.naechstePruefung).diff(moment(row.resolvedAt), 'days')
                if (diff >= 0) return <Tag color="green">OK</Tag>
                return <Tag color="red">{diff} Tage <br/>verspätet</Tag>
            }
        },
        {
          title: ' ',
          key:'details',
          dataIndex: 'details',
          width:'3%',
        }
    ];

    let columns = [];

    if (activ) {
        columns = [...baseColumns, ...columnsActivePruefungen];
    } else {
        columns = [...baseColumns, ...columnsVergangenePruefungen];
    }



    const IconText = ({icon, text}) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );

    return (<Table  dataSource={accountPruefungen}
                columns={columns}
                loading={loading}
                scroll={{ y: '60%' }}
                pagination={{
                    position: ["bottomCenter"],
                    showSizeChanger: false,
                    defaultPageSize: 8
                }}
        />);

}

export default TablePruefungen
