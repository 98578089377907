import * as React from 'react';
import { Result, Button } from 'antd';

const NotFoundContainer = (props) => {

  return (
    <Result
    status="403"
    title="Kein Kontobeziehung vorhanden"
    subTitle="Du wurdest leider noch keinem Konto hinzugefügt! Bitte wende dich an den Verwaltung des entsprechenden Objektes."
    extra={<Button type="primary">Zurück zur Startseite</Button>}
  />
  );
};

export default NotFoundContainer;
