import { API, Auth } from 'aws-amplify';
import axios from 'axios';

const getApiInit = async (body) => {
  const init = {
      headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
  };
  if (body) {
      init.body = body;
  }
  return init;
};

const fileUpload = async (name, uploadPath, formData) => {
    const config = API.configure()
    console.log(config)
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const url = config.endpoints.find(endpoint => endpoint.name === name).endpoint + uploadPath;
    return await axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
        },
    });
};

const getApi = async (api, path) => {
    return API.get(api, path, await getApiInit());
};

const post = async (api, path, body) => {
    return API.post(api, path, await getApiInit(body));
};

const put = async (api, path, body) => {
    return API.put(api, path, await getApiInit(body));
};
const deleteApi = async (api, path) => {
    return API.del(api, path, await getApiInit());
};
const download = async (api, path) => {

    await API.get(api, path, {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            Accept: 'application/octet-stream',
        },
        responseType: 'blob',
        response: true
    })
        .then((response) => {
            const blob = new Blob([response.data], { type: 'application/octet-stream' })

            const header = response.headers['content-disposition'] ?? '';

            const filename = header.split('"')[1]

            if (typeof window.navigator.msSaveBlob !== 'undefined') {
                // IE workaround for "HTML7007: One or more blob URLs were
                // revoked by closing the blob for which they were created.
                // These URLs will no longer resolve as the data backing
                // the URL has been freed."
                window.navigator.msSaveBlob(blob, filename)
            } else {
                const blobURL = window.URL.createObjectURL(blob)
                const tempLink = document.createElement('a')
                tempLink.style.display = 'none'
                tempLink.href = blobURL
                tempLink.setAttribute('download', filename)

                // Safari thinks _blank anchor are pop ups. We only want to set _blank
                // target if the browser does not support the HTML5 download attribute.
                // This allows you to download files in desktop safari if pop up blocking
                // is enabled.
                if (typeof tempLink.download === 'undefined') {
                    tempLink.setAttribute('target', '_blank')
                }

                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
                window.URL.revokeObjectURL(blobURL)
            }
        })

}

const ApiWrapper = {
    get: getApi,
    post,
    put,
    del: deleteApi,
    download,
    fileUpload,
};

export default ApiWrapper;
