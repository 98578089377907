import React, { useState, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Form, Input, Button, notification, Spin, Row, Col } from 'antd';
import Icon from '@ant-design/icons';

import Footer from "../Components/Footer";
import Header from "../Components/Header";
import GlobalStyles from "../globalStyles";
import Slide from "react-reveal/Slide";
import SvgIcon from "../common/SvgIcon";

/** App theme */
import { colors } from '../Themes/Colors';
import FormWrapper from '../Components/Styled/FormWrapper';

import { useSearchParams } from 'react-router-dom';

const PasswordResetContainer = (props) => {

  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

    const [searchParams] = useSearchParams();

  const onFinish = (values) => {

    let { password, code } = values;
    let username = searchParams.get('username');

    Auth.forgotPasswordSubmit(username.trim(), code.trim(), password.trim())
      .then(() => {
        notification.success({
          message: 'Erfolg!',
          description: 'Das Passwort wurde zurückgesetzt!',
          placement: 'topRight',
          duration: 1.5,
          onClose: () => {
            setRedirect(true);
          }
        });
      })
      .catch(err => {
        notification['error']({
          message: 'Fehler',
          description: err.message,
          placement: 'topRight',
          duration: 1.5
        });

        setLoading(false);
      });

    // show loader
    setLoading(true);

  };

  return (
        <React.Fragment>
            <GlobalStyles />
              <Header showPortalHeader={true} />
              <Row type="flex" justify="space-between" align="middle">
                <Col lg={8} md={8} sm={24}>
                <FormWrapper onFinish={onFinish} form={form}>
                  <div className="text-center">
                    <p>Prüfe dein E-Mail Postfach</p>
                  </div>
                  <Form.Item name="code" rules={[
                            {
                              required: true,
                              message: 'Bitte geben den Bestätigungscode ein!'
                            }
                          ]}>
                    <Row>
                      <Col lg={24}>
                          <Input
                            prefix={<Icon type="lock" style={{ color: colors.transparentBlack }} />}
                            placeholder="Bestätigungscode eingeben"
                          />
                      </Col>
                    </Row>
                  </Form.Item>

                  <React.Fragment>
                  <p>Das Passwort muss mindestens 8 Stellen lang sein und enthalten: </p>
                  <ul>
                      <li>mindestens eine Zahl (0-9),</li>
                      <li>mindestens ein Großbuchstabe,</li>
                      <li>mindestens ein Kleinbuchstabe.</li>
                  </ul>
                  </React.Fragment>

                  <Form.Item name="password" rules={[
                          { required: true, message: 'Bitte geben Sie das neue Passwort ein' },
                        ]} hasFeedback>
                        <Row>
                      <Col lg={24}>
                          <Input.Password placeholder="Neues Passwort"/>
                      </Col>
                    </Row>

                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                            {
                              required: true,
                              message: 'Bitte bestätige dein Passwort'
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const pass = getFieldValue('password');
                                if (!value || pass === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject('Die Passwörter stimmen nicht überein!');
                              },
                            })
                          ]}>
                    <Row>
                      <Col lg={24}>
                          <Input.Password placeholder="Passwort bestätigen" />
                      </Col>
                    </Row>
                  </Form.Item>

                  <Form.Item className="text-center">
                    <Row>
                      <Col lg={24}>
                        <Button style={{ width: '100%' }} type="primary" htmlType="submit" className="login-form-button">
                          {loading ? (
                            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
                          ) : (
                            'Passwort vergeben'
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </FormWrapper>
                {redirect && <Navigate to={{ pathname: '/login' }} />}
          </Col>
          <Col lg={12} md={12} sm={24}>
            <Slide right>
              <SvgIcon
                src={"product-launch.svg"}
                className="about-block-image"
                width="80%"
                height="80%"
              />
              </Slide>
          </Col>
        </Row>
      <Footer />
  </React.Fragment>
  );
}

export default PasswordResetContainer;
